import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";

import { ReactComponent as ModalClose } from "../../../assets/images/share/modal-close.svg";
import styles from "./reportcalendar.module.scss";
import "react-datepicker/dist/react-datepicker.css";

import { localDate, selectStartDate, selectEndDate } from "../../../store/dateSlice";

export const ReportCalendar = ({ setIsOpen, handleCloseModal }) => {
    const dispatch = useDispatch();
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const startToday = today.setDate(today.getDate() - 8);
    const endToday = today.setDate(today.getDate() - 2);
    const reduxStartDate = useSelector(selectStartDate);
    const reduxEndDate = useSelector(selectEndDate);
    const [selectedDates, setSelectedDates] = useState([]);
    const [startDate, setStartDate] = useState(new Date(reduxStartDate));
    const [endDate, setEndDate] = useState(new Date(reduxEndDate));
    
    const dateRefs = useRef({});
    const modalRef = useRef(null);

    const handleDateChange = (dates) => {
        // if (!dateRange.start || (dateRange.start && dateRange.end)) {
        //     // 시작 날짜가 없거나, 시작과 끝 날짜가 모두 선택된 경우
        //     setDateRange({ start: date, end: null });
        // } else {
        //     // 시작 날짜가 선택된 경우
        //     setDateRange({ start: dateRange.start, end: date });
        //     const selectedDates = [];
        //     const start = dateRange.start < date ? dateRange.start : date;
        //     const end = dateRange.start < date ? date : dateRange.start;
    
        //     // 시작과 끝 날짜 사이의 모든 날짜를 선택된 날짜로 추가
        //     for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        //         selectedDates.push(new Date(d));
        //     }
        //     setSelectedDates(selectedDates);
        //     console.log(selectedDates);
        // }
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onQueryClick = () => {
        if (startDate && endDate) {
            // Redux 상태 업데이트
            dispatch(
                localDate({
                    startDate: startDate.toISOString().split("T")[0], // YYYY-MM-DD 형식
                    endDate: endDate.toISOString().split("T")[0],
                })
            );

            handleCloseModal();
        }
    };
    
    const highlightClass = (date) => {
        const isSelected = selectedDates.some(
            (selectedDate) => selectedDate.getTime() === date.getTime()
        );

        if (!dateRefs.current[date]) {
            dateRefs.current[date] = React.createRef();
        }

        if (isSelected) {
            return "selected-date"; 
        } else {
            return ""; 
        }
    };

    useEffect(() => {
        Object.keys(dateRefs.current).forEach((dateKey) => {
            const dateElement = dateRefs.current[dateKey]?.current;
            if (dateElement) {
                dateElement.classList.remove("react-datepicker__day--keyboard-selected");
            }
        });
    }, [selectedDates]);

    const getYear = (date) => date instanceof Date && !isNaN(date) ? date.getFullYear() : new Date().getFullYear();
    const getMonth = (date) => date instanceof Date && !isNaN(date) ? date.getMonth() : new Date().getMonth();

    const YEARS = Array.from({ length: getYear(new Date()) + 1 - 2000 }, (_, i) => getYear(new Date()) - i);
    const MONTHS = [
        '1월', '2월', '3월', '4월', '5월', '6월',
        '7월', '8월', '9월', '10월', '11월', '12월'
    ];

    const WEEKDAYS = ['월', '화', '수', '목', '금', '토', '일'];

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        
    }, []);

    return (
        <div className={styles.modalContent} ref={modalRef}>
            <div className={styles.modalHeader}>
                <p className={styles.date}>{startDate ? startDate.toLocaleDateString().replace(/\. /g, '.').replace(/\.$/, '') : "시작 날짜"}</p> 
                <p className={styles.hipen} > - </p>
                <p className={styles.date}>{endDate ? endDate.toLocaleDateString().replace(/\. /g, '.').replace(/\.$/, '') : "종료 날짜"}</p> 
                <div onClick={handleCloseModal} className={styles.dateChangeBtn} >
                    <button>조회 기간 변경</button>
                </div>
            </div>
            <div className={styles.modalBody}>
            <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                dayClassName={highlightClass}
                showYearDropdown
                maxDate={yesterday}
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                renderCustomHeader={({
                    date,
                    changeYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className={styles.customHeaderContainer}>
                        <div className={styles.customHeaderBox}>
                            <button
                                type='button'
                                onClick={decreaseMonth}
                                className={styles.monthButton}
                                disabled={prevMonthButtonDisabled}
                            >
                                {"<"}
                            </button>
                            <div>
                                <span className={styles.month}>{MONTHS[getMonth(date)]}</span>
                                <select
                                    value={getYear(date)}
                                    className={styles.year}
                                    onChange={({ target: { value } }) => changeYear(+value)}
                                >
                                    {YEARS.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                type='button'
                                onClick={increaseMonth}
                                className={styles.monthButton}
                                disabled={nextMonthButtonDisabled}
                            >
                                {">"}
                            </button>
                        </div>
                        <div className={styles.weekdayContainer}>
                            {WEEKDAYS.map((day, index) => (
                                <div key={index} className={styles.weekday}>
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            />
                <div className={styles.buttonContainer}>
                    <button onClick={onQueryClick}>
                        조회하기
                    </button>
                </div>
            </div>
        </div>
    );
};
