import React, { useState, useEffect } from 'react';

import styles from "../scss/dadetailanalysis.module.scss";
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google-da.svg'

import { DaDetailCreativeDownloadApi } from '../../../../../api/reportDA/campaign/DaDetailAnalysisApi';

import { FunnelTable } from '../../../../utils/table/FunnelTable';
import Loading from '../../../../utils/loading/Loading';

export const DaCreativeAnalysis = ({
        mediaInfoId, 
        adAccountsId, 
        startDate, 
        endDate, 
        adLevel, 
        fetchData, 
        loading, 
        data, 
        total, 
        buttonClicked, 
        setButtonClicked, 
        handlePageChange, 
        currentPage, 
        pageChange, 
        setPageChange,
        reportType
    }) => {
    const [orderBy, setOrderBy] = useState(() => {
        switch (mediaInfoId) {
            case 7:
                return "creative_name ASC";
            case 100:
                return "ad_name ASC";
            default:
                return "ad_name ASC";
        }
    });
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': true,
        '전환수': true,
        '전환 매출액': true,
        '광고수익률': true,
    });

    const mediaName = () => {
        if (mediaInfoId === 6) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const defaultOrderBy = () => {
        if (mediaInfoId === 6) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "creative_name ASC"; 
        } else if (mediaInfoId === 100) { 
            return "ad_name ASC"; 
        }
        return "unknown";
    };

    useEffect(() => {
        fetchData(orderBy);
        setButtonClicked(null);
        setPageChange(null);
    }, [startDate, endDate, orderBy, pageChange, buttonClicked]);

    // useEffect(() => {
    //     if (buttonClicked === "버튼클릭" || pageChange === "click") {
    //         fetchData(orderBy);
    //         setButtonClicrrrrrked(null);
    //         setPageChange(null);
    //     }
    // }, [startDate, endDate, orderBy, pageChange]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    // console.log(data)

    const transformedData = data.data.map(item => {
        let urlName = "";
        switch (mediaInfoId) {
            case 100:
                urlName = `https://tpc.googlesyndication.com/simgad/${item.creative_url_name}`
                break;
            default:
                urlName = item.creative_url_name
        }

        return {
            "소재": urlName,
            "광고비": item.tot_cost,
            "노출수": item.tot_impression,
            "클릭수": item.tot_click,
            "클릭률": item.click_rate,
            "전환수": item.tot_conversion_count,
            "전환 매출액": item.tot_conversion_amount,
            "광고수익률": item.roas,
        };
    });

    const transformedTotalData = () => {
        return {
            "소재": "총합",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "전환수": total.data.tot_conversion_count,
            "전환 매출액": total.data.tot_conversion_amount,
            "광고수익률": total.data.roas,
        };
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 6:
                return <ExcelDownloadNaver />;
            case 7:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            default:
                return ; // 기본값
        }
    };

    const handleDownload = async () => {
        let fields = [];
    
        // selectedButtons가 true인 버튼에 따라 fields 설정
        if (Object.values(selectedButtons).every(value => value === true)) {
            fields.push('all'); // 모든 버튼이 true일 경우
        } else {
            // selectedButtons에서 true인 항목만 fields에 추가
            for (const [key, value] of Object.entries(selectedButtons)) {
                if (value) {
                    switch (key) {
                        case '클릭률':
                            fields.push('click_rate');
                            break;
                        case '전환수':
                            fields.push('tot_conversion_count');
                            break;
                        case '전환 매출액':
                            fields.push('tot_conversion_amount');
                            break;
                        case '광고수익률':
                            fields.push('roas');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click');
    
        try {
            const response = await DaDetailCreativeDownloadApi(
                mediaName(),
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob'
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    return (
        <>
            <div className={styles[`creativeAnalysisTable${mediaInfoId}`]}>
                <div className={styles.dataSelectBox}>
                    <div className={styles[`dataSelectButton${mediaInfoId}`]}></div>
                    <button className={styles.excelDownload} onClick={handleDownload}>
                        {getExcelDownloadIcon()}
                    </button>
                </div>
                <FunnelTable 
                    defaultHeaders={['소재','광고비','노출수','클릭수']} 
                    headers={selectedButtons} 
                    items={transformedData} 
                    totalItems={transformedTotalData()} 
                    pageData={data.page}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    mediaInfoId={mediaInfoId}
                    adLevel={adLevel}
                    reportType={reportType}
                    setOrderBy={setOrderBy}
                    defaultOrderBy={defaultOrderBy()}
                />
            </div>
        </>
    );
};
