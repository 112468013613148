import React from "react";

import styles from "../scss/resultweekly.module.scss";

import { ResultBarLine } from "./ResultBarLine";
import { ResultLine } from "./ResultLine";
import { ResultScoreCard } from "./ResultScoreCard";
import { ResultMediaCard } from "./ResultMediaCard";

export const ResultWeekly = ({ startDate, endDate }) => {
    return (
        <div className={styles.resultWeek}>
            <p className={styles.resultWeekTitle}>매출 추세</p>
            <div className={styles.resultChartBox}>
                <ResultBarLine />
                <ResultLine />
            </div>
            <ResultScoreCard startDate={startDate} endDate={endDate} />
            <ResultMediaCard startDate={startDate} endDate={endDate} />
        </div>
    )
}