import React from 'react'

import { Bar, Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement
);


export const PredBarLine = ({ data, lineLabel, barLabel, media, labels }) => {

    const chartData = {
        labels: ['2024.08.08', '2024.08.09', '2024.08.10', '2024.08.11', '2024.08.12', '2024.08.13', '2024.08.14'],
        datasets: [
            {
                type: 'line',
                label: '총 수익',
                data: [10, 15, 8, 20, 25, 18, 30],
                borderColor: 'rgba(30, 0 ,203, 1)',
                backgroundColor: 'rgba(30, 0 ,203, 1)',
                yAxisID: 'y2',
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 7,
                tension: 0,
            },
            {
                type: 'bar',
                label: '네이버',
                data: [2000000, 2500000, 1800000, 2200000, 2700000, 2300000, 3000000],
                backgroundColor: 'rgba(0, 176, 80, 0.8)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '카카오',
                data: [1500000, 2000000, 1600000, 2100000, 2200000, 2000000, 2500000],
                backgroundColor: 'rgba(254, 194, 32, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '구글',
                data: [1000000, 1200000, 900000, 1300000, 1500000, 1400000, 1600000],
                backgroundColor: 'rgba(214, 73, 87, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
            {
                type: 'bar',
                label: '메타',
                data: [800000, 900000, 600000, 700000, 1200000, 1000000, 1400000],
                backgroundColor: 'rgba(0, 114, 240, 1)',
                yAxisID: 'y1',
                stack: 'stack1',
                barPercentage: 1,
                categoryPercentage: 0.6,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y1: {
                type: 'linear',
                position: 'left',
                ticks: {
                    callback: (value) => `${value / 10000}만`, // 백만 단위로 표시
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    display: false
                }
            },
            y2: {
                type: 'linear',
                position: 'right',
                beginAtZero: true,
                grid: {
                    drawOnChartArea: false,
                    display: false
                },
                ticks: {
                    callback: (value) => `${value}`, // 수익률 (값 그대로 표시)
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                }
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <div style={{ width: '748px', height: '200px' }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};


export const PredSingleBarLine = ({ data, width, height }) => {
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: true
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
            y2: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            },
            layout: {
                padding: {
                    
                }
            },
            plugins: {
                legend: {
                    display: false,
                    position: "top",
                    align: "start",
                    margin: {
                        bottom: 10,
                    },
                    labels: {
                        // usePointStyle: true,
                        display: false,
                        boxWidth: 40,
                        boxHeight: 0,
                        color: '#000',
                        font: {
                            family: "Pretendard",
                            size: 12,
                            weight: "600",
                        },
                    },
                },
                datalabels: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
        },
    };

    const chartData = {
        labels: data.data.map(item => item.date),
        datasets: [
            {
                yAxisID: "y2",
                type: "line",
                // label: lineLabel,
                data: data.data.map(item => item.tot_conversion_amount),
                backgroundColor: "rgba(0, 160, 160, 1)",
                borderColor: "rgba(0, 160, 160, 1)",
                borderWidth: 2.5,
                fill: false,
                pointRadius: 2,
                pointBackgroundColor: "rgba(0, 160, 160, 1)",
                pointBorderColor: "rgba(0, 160, 160, 1)",
            },
            {
                yAxisID: "y",
                type: "bar",
                // label: barLabel,
                data: data.data.map(item => item.roas),
                backgroundColor: "rgba(254, 194, 32, 1)",
                borderColor: "rgba(254, 194, 32, 1)",
                barPercentage: 1,
                categoryPercentage: 0.4,
            },
        ]
    };


    return (
        <div style={{width: width, height: height}}>
            <Bar data={chartData} options={options} />
        </div>
    )
}


export const PredLine = ({ data, width, height, pointRadius }) => {
    const borderColor = (media) => {
        if (media === "네이버") {
            return "rgba(0, 176, 80, 1)";
        } else if (media === "카카오") {
            return "rgba(254, 194, 32, 1)";
        } else if (media === "구글") {
            return "rgba(214, 73, 87, 1)";
        } else if (media === "메타") {
            return "rgba(0, 114, 240, 1)";
        }
        return ;
    };
    
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
                align: "center",
                margin: {
                    bottom: 10,
                },
                labels: {
                    // usePointStyle: true,
                    boxWidth: 30,
                    boxHeight: 0,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                },
            },
            datalabels: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "600"
                    },
                    color: '#000',
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
        },
    };

    // console.log(data[0].data.map(item => item.date))
    
    const chartData = {
        labels: data[0].data.map(item => item.date),
        datasets: data.map((campaign, index) => ({
            label: campaign.media_name, // 캠페인 이름
            data: campaign.data.map(item => item.tot_impression), // 클릭 수
            borderColor: borderColor(campaign.media_name),
            backgroundColor: borderColor(campaign.media_name),
            borderWidth: 2,
            pointRadius: pointRadius,
        })),
    };

    return (
        <div style={{width: width, height: height}}>
            <Line options={options} data={chartData} />
        </div>
    )
}

export const PredResultLine = ({ data }) => {
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top",
                align: "center",
                margin: {
                    bottom: 10,
                },
                labels: {
                    // usePointStyle: true,
                    boxWidth: 30,
                    boxHeight: 0,
                    color: '#000',
                    font: {
                        family: "Pretendard",
                        size: 12,
                        weight: "600",
                    },
                },
            },
            datalabels: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: false,
        },
        scales: {
            x: {
                grid: {
                    display: true,
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "600"
                    },
                    color: '#000',
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "600"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
        },
    };

    // console.log(data[0].data.map(item => item.date))
    
    const chartData = {
        labels: data.map(item => item.date),
        datasets: [
            {
                label: "노출수",
                data: data.map((item) => item.impressions),
                borderColor: "#00A0A0",
                backgroundColor: "#00A0A0",
                tension: 0.4,
            },
            {
                label: "클릭수",
                data: data.map((item) => item.clicks),
                borderColor: "#FEC220",
                backgroundColor: "#FEC220",
                tension: 0.4,
            },
        ],
    };

    return (
        <div style={{width: "560px", height: "320px"}}>
            <Line options={options} data={chartData} />
        </div>
    )
}