import React, { useState, useEffect, useMemo } from 'react';

import styles from './targetresult.module.scss';

import { TargetResultApi, ChangeTargetResultApi } from '../../../api/report/TargetResultApi';

export const TargetResult = ({mediaInfoId, reportId, resultData, reportType}) => {
    const [data, setData] = useState({ conversions:0, revenue:0 });
    const [targetData, setTargetData] = useState({ conversions: 0, revenue: 0 });
    const [loading, setLoading] = useState(true);
    const [showTargetInput, setShowTargetInput] = useState(false);
    const [percentages, setPercentages] = useState({ conversions: null, revenue: null });

    const mediaStyle = () => {
        if (mediaInfoId === 100 && reportType) {
            return 200;
        } else {
            return mediaInfoId;
        }
    }

    // console.log(mediaStyle())

    useEffect(() => {
        const fetchData = async () => {
            const result = await TargetResultApi(reportId);
            if (result) {
                setTargetData({conversions: result.data.conversion, revenue: result.data.sale_conversion_amount})
                setData({conversions: resultData.conversion, revenue: resultData.conversionAmount});
            };
        };

        fetchData();
    }, [reportId, resultData]);

    useEffect(() => {
        if (targetData.conversions > 0 || targetData.revenue > 0) {
            const conversionPercent = targetData.conversions > 0
                ? Math.min(100, (data.conversions / targetData.conversions) * 100)
                : 0;
            const revenuePercent = targetData.revenue > 0
                ? Math.min(100, (data.revenue / targetData.revenue) * 100)
                : 0;
            setPercentages({
                conversions: conversionPercent.toFixed(1),
                revenue: revenuePercent.toFixed(1)
            });

            setLoading(false);
        }
    }, [data, targetData]);

    const handleInputChange = (field, value) => {
        const numValue = parseInt(value.replace(/,/g, ''), 10) || 0;
        setTargetData(prev => ({ ...prev, [field]: numValue }));
    };

    const handleSubmit = async () => {
        await ChangeTargetResultApi(reportId, targetData.conversions, targetData.revenue);
        setShowTargetInput(false);
    };

    // const percentages = useMemo(() => {
    //     const conversionPercent = targetData.conversions > 0
    //         ? Math.min(100, (data.conversions / targetData.conversions) * 100)
    //         : 0;
    //     const revenuePercent = targetData.revenue > 0
    //         ? Math.min(100, (data.revenue / targetData.revenue) * 100)
    //         : 0;
    //     return {
    //         conversions: conversionPercent.toFixed(1),
    //         revenue: revenuePercent.toFixed(1)
    //     };
    // }, [data, targetData]);

    // if (loading) {
    //     return (
    //         <>
    //             <div className={styles.targetResult}>로딩중..</div>
    //         </>
    //     );
    // }

    if (!data) {
        return null;
    }

    return (
        <>
            {!showTargetInput ? (
                <>
                {targetData.conversions===0 || targetData.revenue===0 || targetData.conversions===null || targetData.revenue===null ? (
                    <div className={styles.targetResult}>
                        <div className={styles[`targetResultTitle${mediaStyle()}`]}>목표 성과</div>
                        <div className={styles[`targetResultContent${mediaStyle()}`]}>
                            <p>목표 성과를 설정해주세요.</p>
                            <button onClick={() => setShowTargetInput(true)}>목표 성과 설정하기</button>
                        </div>
                    </div>
                ):(
                    <div className={styles.targetResult}>
                        <div className={styles[`targetResultTitle${mediaStyle()}`]}>
                            <p>목표 성과</p>
                            <button onClick={() => setShowTargetInput(true)}>목표 변경하기</button>
                        </div>
                        <div className={styles.targetResultGaugeContent}>
                            <div className={styles.targetResultGaugeItem}>
                                <p className={styles.targetResultGaugeItemTitle}>전환수</p>
                                <div>
                                    <div className={styles.targetGaugeBox}>
                                        <div className={styles[`targetGauge${mediaStyle()}`]}></div>
                                        <div className={styles[`targetGaugeBar${mediaStyle()}`]} style={{width: `${percentages.conversions}%`}}></div>
                                    </div>
                                    <div className={styles.targetGaugeValue}>
                                        <pre className={styles.targetGaugeValueZero}>0</pre>
                                        <pre className={styles[`targetGaugeValueText${mediaStyle()}`]} style={{width: `${percentages.conversions}%`}}>{resultData.conversion ? resultData.conversion.toLocaleString() : '0'}</pre>
                                        <pre className={styles.targetGaugeValueTarget}>{targetData.conversions.toLocaleString()}</pre>
                                    </div>
                                </div>
                                <p className={styles.targetResultGaugeItemPercent}>{percentages.conversions}%</p>
                            </div>
                            <div className={styles.targetResultGaugeItem}>
                                <p className={styles.targetResultGaugeItemTitle}>전환매출액</p>
                                <div>
                                    <div className={styles.targetGaugeBox}>
                                        <div className={styles[`targetGauge${mediaStyle()}`]}></div>
                                        <div className={styles[`targetGaugeBar${mediaStyle()}`]} style={{width: `${percentages.revenue}%`}}></div>
                                    </div>
                                    <div className={styles.targetGaugeValue}>
                                        <pre className={styles.targetGaugeValueZero}>0</pre>
                                        <pre className={styles[`targetGaugeValueText${mediaStyle()}`]} style={{width: `${percentages.revenue}%`}}>{resultData.conversionAmount ? resultData.conversionAmount.toLocaleString() : '0'}</pre>
                                        <pre className={styles.targetGaugeValueTarget}>{targetData.revenue.toLocaleString()}</pre>
                                    </div>
                                </div>
                                <p className={styles.targetResultGaugeItemPercent}>{percentages.revenue}%</p>
                            </div>
                        </div>
                    </div>
                )}
                </>
            ) : (
                <div className={styles.targetInput}>
                    <div className={styles.targetInputTitle}>
                        <p>목표 성과를 입력해주세요.</p>
                        <div>
                            <button className={styles[`targetInputCancel${mediaStyle()}`]} onClick={() => setShowTargetInput(false)}>취소</button>
                            <button className={styles[`targetInputChange${mediaStyle()}`]} onClick={handleSubmit}>변경하기</button>
                        </div>
                    </div>
                    <div className={styles.targetInputContent}>
                        <div className={styles.targetInputContentItem}>
                            <p>전환수</p>
                            <input 
                                placeholder="1,000"
                                value={targetData.conversions === 0 || targetData.conversions === null ? '' : targetData.conversions.toLocaleString()}
                                onChange={(e) => handleInputChange('conversions', e.target.value)}
                            />
                        </div>
                        <div className={styles.targetInputContentItem}>
                            <p>전환매출액</p>
                            <input 
                                placeholder="1,000,000"
                                value={targetData.revenue === 0 || targetData.revenue === null ? '' : targetData.revenue.toLocaleString()}
                                onChange={(e) => handleInputChange('revenue', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
