import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./approve.scss"
import ApproveList from "../../components/approve/ApproveList"
import { path } from "../../store/PathSlice";

const Approve = () => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [approved, setApproved] = useState("");
    const dispatch = useDispatch();
    const location = useLocation();
    const url = location.pathname.split("/").pop();
    dispatch(path(url))

    const handleApprove = () => {
        if (password === "1234") {
            setApproved(true);
            setError("");
            // console.log(approved)
        } else {
            setApproved(false);
            setError("비밀번호가 올바르지 않습니다.");
            alert("비밀번호가 일치하지 않습니다. 개발팀으로 문의해 주세요.")
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleApprove();
        }
    };

    return (
    <div>
        {!approved && (
        <div className="approve-box">
            <h1>요청 결과 조회하기</h1>
            <div className="approve-pw">
                <h3>비밀번호를 입력해 주세요.</h3>
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown} />
                <button onClick={handleApprove}>확인</button>
            </div>
        </div>
        )}
        {approved && <ApproveList />}
    </div>
    )
};

export default Approve