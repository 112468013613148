import React from "react";

import styles from "../scss/googledevicechart.module.scss"

export const GoogleDeviceChart = ({transformedData}) => {
    // console.log(transformedData)
    return (
        <div className={styles.daDeviceChart}>
            <div className={styles.deviceGraphBox}>
                <div className={styles.deviceGraphContainer}>
                    <p className={styles.graphText}>광고비</p>
                    <div className={styles.graphGauge}>
                        <div className={styles.gauge1} style={{width: `${transformedData[1]["광고비 비율"]}%`}}></div>
                        <div className={styles.gauge2} style={{width: `${transformedData[3]["광고비 비율"]}%`}}></div>
                        <div className={styles.gauge3} style={{width: `${transformedData[2]["광고비 비율"]}%`}}></div>
                        <div className={styles.gauge4} style={{width: `${transformedData[4]["광고비 비율"]}%`}}></div>
                    </div>
                    <div className={styles.labelBox}>
                        <p className={styles.squareLabel1}>{transformedData[1]["광고비 비율"]}%</p>
                        <p className={styles.squareLabel2}>{transformedData[3]["광고비 비율"]}%</p>
                        <p className={styles.squareLabel3}>{transformedData[2]["광고비 비율"]}%</p>
                        <p className={styles.squareLabel4}>{transformedData[4]["광고비 비율"]}%</p>
                    </div>
                </div>
                <div className={styles.deviceGraphContainer}>
                    <p className={styles.graphText}>전환 매출액</p>
                    <div className={styles.graphGauge}>
                        <div className={styles.gauge1} style={{width: `${transformedData[1]["전환 매출액 비율"]}%`}}></div>
                        <div className={styles.gauge2} style={{width: `${transformedData[3]["전환 매출액 비율"]}%`}}></div>
                        <div className={styles.gauge3} style={{width: `${transformedData[2]["전환 매출액 비율"]}%`}}></div>
                        <div className={styles.gauge4} style={{width: `${transformedData[4]["전환 매출액 비율"]}%`}}></div>
                    </div>
                    <div className={styles.labelBox}>
                        <p className={styles.squareLabel1}>{transformedData[1]["전환 매출액 비율"]}%</p>
                        <p className={styles.squareLabel2}>{transformedData[3]["전환 매출액 비율"]}%</p>
                        <p className={styles.squareLabel3}>{transformedData[2]["전환 매출액 비율"]}%</p>
                        <p className={styles.squareLabel4}>{transformedData[4]["전환 매출액 비율"]}%</p>
                    </div>
                </div>
            </div>
            <div className={styles.deviceGraphBox}>
                <div className={styles.deviceGraphContainer}>
                    <p className={styles.graphText}>노출수</p>
                    <div className={styles.graphGauge}>
                        <div className={styles.gauge1} style={{width: `${transformedData[1]["노출수 비율"]}%`}}></div>
                        <div className={styles.gauge2} style={{width: `${transformedData[3]["노출수 비율"]}%`}}></div>
                        <div className={styles.gauge3} style={{width: `${transformedData[2]["노출수 비율"]}%`}}></div>
                        <div className={styles.gauge4} style={{width: `${transformedData[4]["노출수 비율"]}%`}}></div>
                    </div>
                    <div className={styles.labelBox}>
                        <p className={styles.squareLabel1}>{transformedData[1]["노출수 비율"]}%</p>
                        <p className={styles.squareLabel2}>{transformedData[3]["노출수 비율"]}%</p>
                        <p className={styles.squareLabel3}>{transformedData[2]["노출수 비율"]}%</p>
                        <p className={styles.squareLabel4}>{transformedData[4]["노출수 비율"]}%</p>
                    </div>
                </div>
                <div className={styles.deviceGraphContainer}>
                    <p className={styles.graphText}>클릭수</p>
                    <div className={styles.graphGauge}>
                        <div className={styles.gauge1} style={{width: `${transformedData[1]["클릭수 비율"]}%`}}></div>
                        <div className={styles.gauge2} style={{width: `${transformedData[3]["클릭수 비율"]}%`}}></div>
                        <div className={styles.gauge3} style={{width: `${transformedData[2]["클릭수 비율"]}%`}}></div>
                        <div className={styles.gauge4} style={{width: `${transformedData[4]["클릭수 비율"]}%`}}></div>
                    </div>
                    <div className={styles.labelBox}>
                        <p className={styles.squareLabel1}>{transformedData[1]["클릭수 비율"]}%</p>
                        <p className={styles.squareLabel2}>{transformedData[3]["클릭수 비율"]}%</p>
                        <p className={styles.squareLabel3}>{transformedData[2]["클릭수 비율"]}%</p>
                        <p className={styles.squareLabel4}>{transformedData[4]["클릭수 비율"]}%</p>
                    </div>
                </div>
            </div>
        </div>
    )
}