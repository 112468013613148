import React, { useState, useEffect } from 'react'

import styles from "../scss/dacampaignsumtable.module.scss"

import { DaCampaignSumTableApi, DaCampaignSumTotalApi } from '../../../../../api/reportDA/campaign/DaCampaignSumApi';

import { ReportTable } from '../../../../utils/table/ReportTable';



export const DaCampaignSumTable = ({mediaInfoId, adAccountsId, startDate, endDate, campaignIds, setCampaignIds, gaugeData, reportType }) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("ad_date ASC");
    const [currentPage, setCurrentPage] = useState(1);
    const [prevDateRange, setPrevDateRange] = useState({ startDate: null, endDate: null });
    const [selectedValue, setSelectedValue] = useState("default"); 
    const [selectedButtons, setSelectedButtons] = useState({
        '전환수': true,
        '전환 매출액': true,
        '광고수익률': true
    });

    const mediaName = () => {
        if (mediaInfoId === 6) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const [dayPerformanceResponse] = await Promise.all([
                DaCampaignSumTableApi(mediaName(), startDate, endDate, adAccountsId, campaignIds, pageSize, row, orderBy)
            ]);
            setData(dayPerformanceResponse);
            
            if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
                const totalPerformanceResponse = await DaCampaignSumTotalApi(mediaName(), startDate, endDate, adAccountsId, campaignIds);
                setTotal(totalPerformanceResponse);
                setPrevDateRange({ startDate, endDate });
                // console.log(dayPerformanceResponse, totalPerformanceResponse)
            }
            
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, row, orderBy, campaignIds]);

    if (loading) {
        return (
            <>
            </>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "일자": item.ad_date_dayweek,
        "캠페인명": item.campaign_name,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "전환수": item.tot_conversion_count,
        "전환 매출액": item.tot_conversion_amount,
        "광고수익률": item.roas,
    }))

    const transformedTotalData = () => {
        return {
            "일자": "총합",
            "캠페인명": "-",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "전환수": total.data.tot_conversion_count,
            "전환 매출액": total.data.tot_conversion_amount,
            "광고수익률": total.data.roas,
        };
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    return (
        <>
            <div className={styles.daCampaignSumTable}>
                <p className={styles.daCampaignSumTitle}>일자별 캠페인 상세 분석</p>
                <div className={styles[`daCampaignSumSelect${mediaInfoId}`]}>
                    <select value={campaignIds} onChange={(e) => {
                        if (e.target.value === "all") {
                            setCampaignIds(gaugeData.data.map(id => id.campaign_id));
                        } else {
                            setCampaignIds([e.target.value]);
                        }
                    }}>
                        <option key="default" value="all">캠페인 선택</option>
                        {gaugeData.data.map(id => (
                            <option key={id.campaign_id} value={id.campaign_id}>{id.campaign_name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <ReportTable 
                defaultHeaders={['일자','캠페인명','광고비','노출수','클릭수']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
                setOrderBy={setOrderBy}
                defaultOrderBy={"ad_date ASC"}
                reportType={reportType}
            />
        </>
    )
}