import React, { useState, useEffect } from "react";

import styles from "../scss/daavgchart.module.scss"
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-da-naver-labels.svg"
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-da-kakao-labels.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-da-google-labels.svg"

import { DaCostAvgChartApi } from "../../../../../api/reportDA/cost/DaCostAvgChartApi";

import { DaBarLine } from "../../../../utils/chart/DaBarLine";
import Loading from "../../../../utils/loading/Loading";

export const DaAvgChart = ({
        mediaInfoId, 
        adAccountsId, 
        startDate,
        endDate
    }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostAvgChartApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    // const testData = {data:[
    //     {conversion_cost: 1564,conversion_rate: 129.95,date: "일",roas: 0.06,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140},
    //     {conversion_cost: 1230, conversion_rate: 149.61 ,date: "월" ,roas: 0.08,tot_conversion_amount: 3067,tot_conversion_count: 3067,tot_cost: 3772810},
    //     {conversion_cost: 2422,conversion_rate: 135.81,date: "화",roas: 0.04,tot_conversion_amount: 5617,tot_conversion_count: 5617,tot_cost: 13609440},
    //     {conversion_cost: 2694,conversion_rate: 124.31,date: "수",roas: 0.04,tot_conversion_amount: 4720,tot_conversion_count: 4720,tot_cost: 12717900},
    //     {conversion_cost: 2561,conversion_rate: 126.63,date: "목",roas: 0.04,tot_conversion_amount: 4489,tot_conversion_count: 4489,tot_cost: 11500570},
    //     {conversion_cost: 2932,conversion_rate: 112.32,date: "금",roas: 0.03,tot_conversion_amount: 3648,tot_conversion_count: 3648,tot_cost: 10696640},
    //     {conversion_cost: 2796,conversion_rate: 123.71,date: "토",roas: 0.04,tot_conversion_amount: 3804,tot_conversion_count: 3804,tot_cost: 10637990},
    // ]}

    return (
        <div className={styles.daAvg}> 
            <div className={styles.daAvgTitle}>요일별 평균 매출 및 평균 광고 비용</div>
            <div className={styles.chartLables}>
                {mediaInfoId === 6 && <NaverLabels />}
                {mediaInfoId === 7 && <KakaoLabels />}
                {mediaInfoId === 100 && <GoogleLabels />}
            </div>
            <div className={styles.daAvgChart}>
                <DaBarLine data={data} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    )
}