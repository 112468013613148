import React, { useState } from 'react'

import styles from './reporttable.module.scss'

import CustomPagination from '../pagination/Pagination'
import {ReactComponent as SortMark} from '../../../assets/images/share/sort.svg'
import {ReactComponent as UpSortMark} from '../../../assets/images/share/upsort.svg'
import {ReactComponent as DownSortMark} from '../../../assets/images/share/downsort.svg'

import { sortItems } from './SortItems'

export const ReportTable = ({ 
        defaultHeaders, 
        headers, 
        items, 
        totalItems, 
        pageData, 
        handlePageChange, 
        currentPage, 
        mediaInfoId,
        setOrderBy,
        defaultOrderBy,
        reportType
    }) => {
    const combinedItems = [totalItems, ...items];
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
    
    const handleSort = (key) => {
        // console.log(key)
        sortItems(key, sortConfig, setSortConfig, setOrderBy, defaultOrderBy);
    };

    // console.log(combinedItems)

    return (
        <>
            <div className={styles.table}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableHeaderRow}>
                        {Array.isArray(defaultHeaders) && defaultHeaders.length > 0 && (
                            defaultHeaders.map((item, index) => (
                                <div key={item} onClick={() => handleSort(item)}>
                                    {item}
                                    <span>
                                        {sortConfig.key === item ? (sortConfig.direction === 'ascending' ? <UpSortMark/> : sortConfig.direction === 'descending' ? <DownSortMark/> : <SortMark />) : <SortMark />}
                                    </span> 
                                </div>
                            ))
                        )}
                        {Object.keys(headers || {}).filter(key => headers[key]).map(key => (
                            <div key={key} onClick={() => handleSort(key)}>
                                {key}
                                <span>
                                    {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? <UpSortMark/> : sortConfig.direction === 'descending' ? <DownSortMark/> : <SortMark />) : <SortMark />}
                                </span> 
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {combinedItems.map((row, index) => (
                        <div key={index} className={styles.tableBodyRow}>
                            {defaultHeaders.map((header, headerIndex) => {
                                const isDateHeader = header === "일자";
                                const isWeekend = isDateHeader && typeof row[header] === 'string' && (row[header].includes('토') || row[header].includes('일'));
                                const displayValue = typeof row[header] === 'number' 
                                    ? (header === "광고비" ? `₩${row[header].toLocaleString()}` 
                                    : header === "전환 매출액" ? `₩${row[header].toLocaleString()}` 
                                    : (["클릭률", "전환율", "광고수익률"].includes(header) ? `${row[header].toFixed(2)}%` : row[header].toLocaleString()))
                                    : row[header];
                                return (
                                    <div key={`default-${headerIndex}`} style={{ color: isWeekend ? 'red' : 'black' }}>
                                        <p>{displayValue}</p>
                                        <span className={styles.tooltipTxt}>{displayValue}</span>
                                    </div>
                                );
                            })}
                            {Object.keys(headers || {}).filter(key => headers[key]).map(key => {
                                const displayValue = typeof row[key] === 'number' 
                                ? (key === "전환 매출액" ? `₩${row[key].toLocaleString()}` 
                                    : (key === "클릭당 비용" ? `₩${row[key].toLocaleString()}` 
                                    : (["클릭률", "전환율", "광고수익률"].includes(key) ? `${row[key].toFixed(2)}%` : row[key].toLocaleString())))
                                : row[key];
                                return (
                                    <div key={key}>
                                        <p>{displayValue}</p>
                                        <span className={styles.tooltipTxt}>{displayValue}</span>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <CustomPagination 
                    total={pageData.count} 
                    views={pageData.page_size}
                    handlePageChange={handlePageChange}
                    page={currentPage}
                    mediaInfoId={mediaInfoId}
                    reportType={reportType}
                />
            </div>
        </>
    );
};
