import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/dateconversion.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { KeywordDateConversionApi } from '../../../../../api/report/keyword/KeywordDateConversionApi';

import { YaxisBarChart } from '../../../../utils/chart/YaxisBarChart';
import Loading from '../../../../utils/loading/Loading';

export const DateConversion = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("count"); // 선택된 값 상태 추가
    const { setHighDay, setLowDay } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const handleResponse = (response) => {
        // response에서 tot_conversion_amount와 day_week를 추출
        const conversionData = response.data.map(item => ({
            day_week: item.day_week,
            tot_conversion_amount: item.tot_conversion_amount
        }));
    
        // tot_conversion_amount가 가장 높은 요일과 가장 낮은 요일 찾기
        const highestDay = conversionData.reduce((prev, current) => 
            (prev.tot_conversion_amount > current.tot_conversion_amount) ? prev : current
        );
        const lowestDay = conversionData.reduce((prev, current) => 
            (prev.tot_conversion_amount < current.tot_conversion_amount) ? prev : current
        );

        // console.log(highestDay, lowestDay)
    
        // 상태 업데이트
        setHighDay({ amount: highestDay.day_week });
        setLowDay({ amount: lowestDay.day_week });
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordDateConversionApi(mediaName(), startDate, endDate, adAccountsId); // 선택된 값 전달
            setData(response);
            setLoading(false);
            handleResponse(response);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <div className={styles.dateConversion}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const chartData = data.data.map(item => ({
        day: item.day_week, // day_week 값을 사용
        value: selectedValue === "amount" ? item.tot_conversion_amount :
               selectedValue === "roas" ? item.roas :
               item.tot_conversion_count
    }));

    const barLabel = () => {
        if (selectedValue === "count") {
            return "전환수";
        } else if (selectedValue === "amount") {
            return "전환 매출액";
        } else if (selectedValue === "roas") {
            return "광고수익률";
        }
    }

    return (
        <div className={styles.dateConversion}>
            <div className={styles.dateConversionTitle}>
                <p>요일별 전환</p>
                <div className={styles[`dateConversionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="count">전환수</option>
                        <option value="amount">전환 매출액</option>
                        <option value="roas">광고수익률</option>
                    </select>
                </div>
            </div>
            <div className={styles.dateConversionContent}>
                <YaxisBarChart data={chartData} barLabel={barLabel()} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    )
}
