import React, { useState, useEffect } from "react";

import styles from "../scss/box/datalinkbox.module.scss"
import DataLinkModal from "../modal/DataLinkModal";

import { ReactComponent as DataLinkGraph } from "../../../../assets/images/datalink/datalink-graph.svg"
import { ReactComponent as GoogleAds } from "../../../../assets/images/datalink/datalink-google.svg";
import { ReactComponent as GA4 } from "../../../../assets/images/datalink/datalink-ga4.svg";
import { ReactComponent as Naver } from "../../../../assets/images/datalink/datalink-naver.svg";
import { ReactComponent as Kakao } from "../../../../assets/images/datalink/datalink-kakaologo.svg";
import { ReactComponent as Facebook } from "../../../../assets/images/datalink/datalink-facebook.svg";

const dataLinks = [
    { Icon: GA4, text: "구글 애널리틱스 4" },
    { Icon: Kakao, text: "카카오모먼트" },
    { Icon: Kakao, text: "카카오키워드" },
    { Icon: Facebook, text: "페이스북" },
    { Icon: GoogleAds, text: "구글광고" },
    { Icon: Naver, text: "네이버 검색광고" },
    { Icon: Naver, text: "네이버 GFA" }
];

const DataLinkBox = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedLink(null);
    };

    const handleLinkClick = (link) => {
        if (link === "구글 애널리틱스 4") {
            alert("준비중인 기능입니다.");
            // window.location.href = "/datalink/ga4";
        } else if (link === "네이버 GFA") {
            alert("준비중인 기능입니다.");
        } else if (link === "페이스북") {
            alert("준비중인 기능입니다.");
        } else {
            setModalOpen(true);
            setSelectedLink(link);
        }
    };

    return (
        <>
            <div className={styles.dataLinkBox}>
                <div className={styles.dataLinkImg}><DataLinkGraph /></div>
                <div className={styles.modalText}>
                    <p className={styles.text1}>데이터  연결하기</p>
                </div>
                <div className={styles.listBox}>
                    {dataLinks.map(({ Icon, text }, index) => (
                        <div
                            key={index}
                            className={styles.list}
                            onClick={() => handleLinkClick(text)}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon />
                            <p>{text}</p>
                        </div>
                    ))}
                </div>
            </div>
            {modalOpen && <DataLinkModal show={modalOpen} handleClose={closeModal} selectedLink={selectedLink} />}
        </>
    );
}

export default DataLinkBox;
