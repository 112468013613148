import React from "react";

import styles from "../scss/kakaodevicechart.module.scss";

export const KakaoDeviceChart = ({ transformedData }) => {
    const graphData = [
        { label: "광고비", key: "광고비 비율" },
        { label: "전환 매출액", key: "전환 매출액 비율" },
        { label: "노출수", key: "노출수 비율" },
        { label: "클릭수", key: "클릭수 비율" },
    ];

    const filteredData = transformedData.slice(1);

    return (
        <div className={styles.daDeviceChart}>
            <div  className={styles.deviceGraphBox}>
            {graphData.map((graph, index) => (
                <>
                    <div className={styles.deviceGraphContainer}>
                        <p className={styles.graphText}>{graph.label}</p>
                        <div className={styles.graphGauge}>
                            {filteredData.map((data, idx) => (
                                <div
                                    key={idx}
                                    className={styles[`gauge${idx + 1}`]} 
                                    style={{ width: `${data[graph.key]}%` }}
                                ></div>
                            ))}
                        </div>
                        <div className={styles.labelBox}>
                            {filteredData.map((data, idx) => (
                                <p
                                    key={idx}
                                    className={styles[`squareLabel${idx + 1}`]}
                                >
                                    {data[graph.key]}%
                                </p>
                            ))}
                        </div>
                    </div>
                </>
            ))}
            </div>
        </div>
    );
};
