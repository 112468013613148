import React from "react";

import styles from "../scss/performancemediacard.module.scss";

export const PerformanceMediaCard = ({ startDate, endDate }) => {
    
    const testData = {
        media:[
        {media: "네이버 - 파워링크"},
        {media: "카카오 - 카카오 키워드"},
        {media: "구글 - 검색 광고"},
        {media: "메타 - 디스플레이 광고"}
        ],
        tot_cost: 3211,
        tot_cost_variance: 32,
        tot_cost_variance_operator: "up",
        impression_cost: 4324,
        impression_cost_variance: 32.23,
        impression_cost_variance_operator: "down",
        tot_click: 32422,
        tot_click_variance: 23.43,
        tot_click_variance_operator: "up",
        tot_conversion_count: 6433,
        tot_conversion_count_variance: 15.38,
        tot_conversion_count_variance_operator: "down",
        tot_conversion_amount: 3222,
        tot_conversion_amount_variance: 34.12,
        tot_conversion_amount_variance_operator: "down"
    }

    const transformedData = [
        {name: '광고비', value: `${testData.tot_cost.toLocaleString()}`, change: testData.tot_cost_variance, operator: testData.tot_cost_variance_operator},
        {name: '노출수', value: `${testData.impression_cost.toLocaleString()}`, change: testData.impression_cost_variance, operator: testData.impression_cost_variance_operator},
        {name: '클릭수', value: `${testData.tot_click.toLocaleString()}`, change: testData.tot_click_variance, operator: testData.tot_click_variance_operator},
        {name: '전환수', value: `${testData.tot_conversion_count.toLocaleString()}`, change: testData.tot_conversion_count_variance, operator: testData.tot_conversion_count_variance_operator},
        {name: '전환 매출액', value: `${testData.tot_conversion_amount.toLocaleString()}`, change: testData.tot_conversion_amount_variance, operator: testData.tot_conversion_amount_variance_operator},
    ]

    const transformedTotal = [
        {name: '이전 광고비', value: `${testData.tot_conversion_count.toLocaleString()}`, change: testData.tot_conversion_count_variance, operator: testData.tot_conversion_count_variance_operator},
        {name: '변동률', value: `${testData.tot_conversion_amount.toLocaleString()}`, change: testData.tot_conversion_amount_variance, operator: testData.tot_conversion_amount_variance_operator},
        {name: '변경된 광고비', value: `${testData.tot_click.toLocaleString()}`, change: testData.tot_click_variance, operator: testData.tot_click_variance_operator}
    ]
    
    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    return (
        <div className={styles.perfMediaCard}>
            {testData.media.map((item, index) => {
                return (
                    <div key={index} className={item.media === "메타 - 디스플레이 광고" ? styles.noDataBox : styles.cardBox}>
                        <p className={styles.cardTxt1}>{item.media}</p>
                        <div className={styles.mediaCard}>
                            {transformedTotal.map((card, index) => (
                                <div key={index} className={card.name === "변동률" ? `${styles.miniCard} ${getChangeClassName(card.operator)}` : styles.scoreCard}>
                                    <div className={styles.scoreCardTitle}>{card.name}</div>
                                    {card.name === "변동률" ? (
                                        <div className={`${styles.scoreCardChange} ${getChangeClassName(card.operator)}`}>
                                            {card.operator === "up" ? `+${card.change}%` : card.operator === "down" ? `-${card.change}%` : "-"}
                                        </div>
                                    ):(
                                        <div className={styles.scoreCardValue}>{card.value}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <p className={styles.cardTxt2}>이전 성과 ({startDate} ~ {endDate})</p>
                        <div className={styles.tableCol}>
                            {transformedData.map((table, index) => (
                                <div key={`${table.name}-${index}`}>
                                    <div className={styles.tableHead}>
                                        <p>{table.name}</p>
                                    </div>
                                    <div className={styles.tableBody}>
                                        <p>{table.value}</p>
                                        <div className={`${styles.scoreCardChange} ${getChangeClassName(table.operator)}`}>
                                            {table.operator === "up" ? `+${table.change}%` : table.operator === "down" ? `-${table.change}%` : "-"}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {item.media === "메타 - 디스플레이 광고" ? (
                            <div className={styles.noDataTxt}>
                                <p>메타 정보 없음</p>
                                <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                            </div>
                        ):( 
                            <></>
                        )}
                    </div>
                );
            })}
        </div>
    );
}