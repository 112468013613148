import React, { useState, useEffect, useContext } from 'react'

import styles from '../scss/datrendtable.module.scss'
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg'
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg'
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google-da.svg'

import { SummaryContext } from '../../../summary/DaSummaryProvider'

import { DaCostTrendTableApi, DaCostTrendTableTotalApi, DaCostTrendTableDownloadApi } from '../../../../../api/reportDA/cost/DaCostTrendTableApi'

import { ReportTable } from '../../../../utils/table/ReportTable'
import Loading from '../../../../utils/loading/Loading'

export const DaTrendTable = ({
    mediaInfoId, 
    adAccountsId, 
    startDate, 
    endDate,
    selectedValue,
    reportType}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("ad_date ASC");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': false,
        '클릭당 비용': false,
        '전환 매출액': true,
        '전환율': false,
        '전환수': true,
        '광고수익률': true
    });
    const [prevDateRange, setPrevDateRange] = useState({ startDate: null, endDate: null });
    const { setConversion, setConvAmount } = useContext(SummaryContext)

    // const mediaInfoId = 6
    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        } else if (mediaInfoId === 6) {
            return "gfa";
        } else if (mediaInfoId === 7) {
            return "kakao";
        }
        return "unknown";
    };

    const handleResponse = (response) => {
        // console.log(response)
        setConversion(response.data.tot_conversion_count);
        setConvAmount(response.data.tot_conversion_amount);
    }

    useEffect(() => {
        const fetchData = async () => {
            const [dayPerformanceResponse] = await Promise.all([
                DaCostTrendTableApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy, selectedValue)
            ]);
            setData(dayPerformanceResponse);
            // console.log(dayPerformanceResponse)
            
            if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
                const totalPerformanceResponse = await DaCostTrendTableTotalApi(mediaName(), startDate, endDate, adAccountsId, selectedValue);
                setTotal(totalPerformanceResponse);
                setPrevDateRange({ startDate, endDate });
                handleResponse(totalPerformanceResponse);
                // console.log(totalPerformanceResponse)
            }
            
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, row, orderBy]);

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    // const testData = {
    //     data: [
    //         {ad_date_dayweek: "2024-10-13 (일)",click_rate: 0.35,conversion_rate: 149.61,cost_click: 1840,roas: 0.08,row_num: 1,tot_click: 2050,tot_conversion_amount: 3067,tot_conversion_count: 3067,tot_cost: 3772810,tot_impression: 589203},
    //         {ad_date_dayweek: "2024-10-14 (월)",click_rate: 0.51,conversion_rate: 135.81,cost_click: 3290,roas: 0.04,row_num: 2,tot_click: 4136,tot_conversion_amount: 5617,tot_conversion_count: 5617,tot_cost: 13609440,tot_impression: 809571},
    //         {ad_date_dayweek: "2024-10-15 (화)",click_rate: 0.48,conversion_rate: 124.31,cost_click: 3349,roas: 0.04,row_num: 3,tot_click: 3797,tot_conversion_amount: 4720,tot_conversion_count: 4720,tot_cost: 12717900,tot_impression: 787427},
    //         {ad_date_dayweek: "2024-10-16 (수)",click_rate: 0.46,conversion_rate: 126.63,cost_click: 3244,roas: 0.04,row_num: 4,tot_click: 3545,tot_conversion_amount: 4489,tot_conversion_count: 4489,tot_cost: 11500570,tot_impression: 768065},
    //         {ad_date_dayweek: "2024-10-17 (목)",click_rate: 0.42,conversion_rate: 112.32,cost_click: 3293,roas: 0.03,row_num: 5,tot_click: 3248,tot_conversion_amount: 3648,tot_conversion_count: 3648,tot_cost: 10696640,tot_impression: 779521},
    //         {ad_date_dayweek: "2024-10-18 (금)",click_rate: 0.43,conversion_rate: 123.71,cost_click: 3459,roas: 0.04,row_num: 6,tot_click: 3075,tot_conversion_amount: 3804,tot_conversion_count: 3804,tot_cost: 10637990,tot_impression: 715958},
    //         {ad_date_dayweek: "2024-10-19 (토)",click_rate: 0.39,conversion_rate: 129.95,cost_click: 2032,roas: 0.06,row_num: 7,tot_click: 1903,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140,tot_impression: 490716},
    //         {ad_date_dayweek: "2024-10-19 (토)",click_rate: 0.39,conversion_rate: 129.95,cost_click: 2032,roas: 0.06,row_num: 7,tot_click: 1903,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140,tot_impression: 490716},
    //         {ad_date_dayweek: "2024-10-19 (토)",click_rate: 0.39,conversion_rate: 129.95,cost_click: 2032,roas: 0.06,row_num: 7,tot_click: 1903,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140,tot_impression: 490716},
    //         {ad_date_dayweek: "2024-10-19 (토)",click_rate: 0.39,conversion_rate: 129.95,cost_click: 2032,roas: 0.06,row_num: 7,tot_click: 1903,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140,tot_impression: 490716},
    //     ],
    //     page: {count: 7, row_num: 0, page_size: 10, total_page_num: 1}
    // };

    // const testTotalData = {
    //     data : { 
    //         click_rate: 3.04,conversion_rate: 902.34,cost_click: 20507,roas: 0.33,tot_click: 21754,tot_conversion_amount: 27818,tot_conversion_count: 27818,tot_cost: 66803490,tot_impression: 4940461
    //     }
    // }

    const transformedData = data.data.map(item => ({
        "일자": item.ad_date_dayweek,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "클릭당 비용": item.cost_click,
        "전환 매출액": item.tot_conversion_amount,
        "전환율": item.conversion_rate,
        "전환수": item.tot_conversion_count,
        "광고수익률": item.roas,
    }))

    const transformedTotalData = () => {
        return {
            "일자": "총합",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "클릭당 비용": total.data.cost_click,
            "전환 매출액": total.data.tot_conversion_amount,
            "전환율": total.data.conversion_rate,
            "전환수": total.data.tot_conversion_count,
            "광고수익률": total.data.roas,
        };
    };

    const toggleButton = (buttonName) => {
        setSelectedButtons(prev => ({
            ...prev,
            [buttonName]: !prev[buttonName]
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 5:
                return <ExcelDownloadNaver />;
            case 89:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            case 6:
                return <ExcelDownloadNaver />;
            case 7:
                return <ExcelDownloadKakao />;
            default:
                return ; // 기본값
        }
    };
    
    const handleDownload = async () => {
        let fields = [];
    
        // selectedButtons가 true인 버튼에 따라 fields 설정
        if (Object.values(selectedButtons).every(value => value === true)) {
            fields.push('all'); // 모든 버튼이 true일 경우
        } else {
            // selectedButtons에서 true인 항목만 fields에 추가
            for (const [key, value] of Object.entries(selectedButtons)) {
                if (value) {
                    switch (key) {
                        case '클릭률':
                            fields.push('click_rate');
                            break;
                        case '클릭당 비용':
                            fields.push('cost_click');
                            break;
                        case '전환 매출액':
                            fields.push('tot_conversion_amount');
                            break;
                        case '전환율':
                            fields.push('conversion_rate');
                            break;
                        case '전환수':
                            fields.push('tot_conversion_count');
                            break;
                        case '광고수익률':
                            fields.push('roas');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click');
    
        try {
            const response = await DaCostTrendTableDownloadApi(
                mediaName(),
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob',
                selectedValue
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    return (
        <div className={styles.daTrendTable}>
            <div className={styles.dataSelectBox}>
                <div className={styles[`dataSelectButton${mediaInfoId}`]}>
                    {Object.keys(selectedButtons).map((buttonName) => (
                        <button
                            key={buttonName}
                            className={selectedButtons[buttonName] ? styles.active : ''}
                            onClick={() => toggleButton(buttonName)}
                        >
                            {buttonName}
                        </button>
                    ))}
                </div>
                <button className={styles.excelDownload} onClick={handleDownload}>
                    {getExcelDownloadIcon()}
                </button>
            </div>
            <ReportTable 
                defaultHeaders={['일자','광고비','노출수','클릭수']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
                setOrderBy={setOrderBy}
                defaultOrderBy={"ad_date ASC"}
                reportType={reportType}
            />
        </div>
    )
}
