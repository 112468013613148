import React from "react";
import { useSelector } from "react-redux";

import styles from "./advcostreport.module.scss"

import { selectStartDate, selectEndDate } from '../../../store/dateSlice';

import { AdvCostPrev } from "./prev/AdvCostPrev";
import { PredCondition } from "./condition/PredCondition";
import { PredScoreCard } from "./analysis/PredScoreCard";
import { PredImpClick } from "./analysis/PredImpClick";
import { PredAdvResult } from "./analysis/PredAdvResult";
import { MediaPerformance } from "./performance/MediaPerformance";


const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

const formatSlashDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`; 
}


export const AdvCostReport = () => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const slashStartDate = formatSlashDate(useSelector(selectStartDate))
    const slashEndDate = formatSlashDate(useSelector(selectEndDate))

    return (
        <div className={styles.advCost}>
            <div className={styles.advCostHeader}>
                <p>(보고서 이름) 광고비 성과 예측 보고서</p>
            </div>
            <div className={styles.advCostBody}>
                <p className={styles.advCostDate}>{slashStartDate} ~ {slashEndDate}</p>
                <AdvCostPrev startDate={startDate} endDate={endDate} />
                <PredCondition startDate={startDate} endDate={endDate} />
                <p className={styles.advCostDate} style={{marginBottom: "25px"}}>예측 결과 분석</p>
                <PredScoreCard startDate={startDate} endDate={endDate} slashStartDate={slashStartDate} slashEndDate={slashEndDate} />
                <div className={styles.advCostChart}>
                    <PredImpClick startDate={startDate} endDate={endDate} />
                    <PredAdvResult startDate={startDate} endDate={endDate} />
                </div>
                <MediaPerformance startDate={startDate} endDate={endDate} />
            </div>
        </div>
    )
}