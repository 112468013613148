import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './reportsa.module.scss';
import { ReactComponent as Memo } from '../../../assets/images/report/report-sa-memo.svg';
import { ReactComponent as Calendar } from '../../../assets/images/report/report-sa-calendar.svg';

import { localDate } from '../../../store/dateSlice';

import { ReportKeyword } from './keyword/ReportKeyword';
import { ReportClick } from './click/ReportClick';
import { ReportCost } from './cost/ReportCost';
import { TargetResult } from '../target/TargetResult';
import { Summary } from '../summary/Summary';
import { ReportCalendar } from '../calendar/ReportCalendar';
import { SummaryProvider } from '../summary/SummaryProvider';

export const ReportSA = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedButton, setSelectedButton] = useState('keyword');
    const [popup, setPopup] = useState();
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const reportName = location.state?.reportName
    const adAccountsId = location.state?.adAccountsId
    const mediaInfoId = location.state?.mediaInfoId
    const reportId = location.state?.reportId
    const [resultData, setResultData] = useState({conversion: null, conversionAmount: null })

    const initialStartDate = new Date();
    const initialEndDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 8);
    initialEndDate.setDate(initialEndDate.getDate() - 2);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const startDate = formatDate(initialStartDate);
        const endDate = formatDate(initialEndDate);

        // Redux 상태 업데이트
        dispatch(localDate({ startDate, endDate }));

        // localStorage 초기화
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
    }, [reportId, dispatch]);

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const handleMemoClick = () => {
        openEditorWindow();
    };

    const handleCalendarClick = () => {
        setIsCalendarOpen(true);
    };

    const handleCloseCalendar = () => {
        setIsCalendarOpen(false);
    };

    const openEditorWindow = () => {
        const currentOrigin = window.location.origin;
        const editorWindow = window.open(
            `${currentOrigin}/report/memo`,
            "_blank",
            "width=700,height=800"
        );
        setPopup(editorWindow);
    };

    const getButtonClassName = (buttonType) => {
        if (mediaInfoId === 5) {
            return selectedButton === buttonType ? styles.reportNaverSaButtonActive : styles.reportNaverSaButton;
        } else if (mediaInfoId === 89) {
            return selectedButton === buttonType ? styles.reportKakaoSaButtonActive : styles.reportKakaoSaButton;
        } else if (mediaInfoId === 100) {
            return selectedButton === buttonType ? styles.reportGoogleSaButtonActive : styles.reportGoogleSaButton;
        }
        // 추가 조건을 여기에 계속 추가할 수 있습니다.
        return styles.reportSaButton; // 기본 클래스
    };

    return (
        <SummaryProvider>
            <div className={styles.reportSa}>
                <div className={styles.reportSaHeader}>
                    <p className={styles.reportSaHeaderTitle}>{reportName}</p>
                    {isCalendarOpen && <ReportCalendar handleCloseModal={handleCloseCalendar} />}
                    <div className={styles.reportSaHeaderIcon}>
                        
                        <Calendar onClick={handleCalendarClick} style={{cursor: 'pointer'}} />
                        <div onClick={handleMemoClick} style={{cursor: 'pointer'}}>
                            <Memo />
                        </div>
                    </div>
                </div>
                <div className={styles.reportSaButtonContainer}>
                    <div className={styles.reportSaButtonBox}>
                        <button 
                            className={getButtonClassName('keyword')} 
                            onClick={() => handleButtonClick('keyword')}
                        >
                            키워드/전환
                        </button>
                        <button 
                            className={getButtonClassName('impression')} 
                            onClick={() => handleButtonClick('impression')}
                        >
                            노출/클릭
                        </button>
                        <button 
                            className={getButtonClassName('cost')} 
                            onClick={() => handleButtonClick('cost')}
                        >
                            비용
                        </button>
                    </div>
                </div>
                <div className={styles.reportSaContent}>
                    {selectedButton === 'keyword' && <ReportKeyword mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} setResultData={setResultData} />}
                    {selectedButton === 'impression' && <ReportClick mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} />}
                    {selectedButton === 'cost' && <ReportCost mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} />}
                    <div>
                        <TargetResult mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} reportId={reportId} resultData={resultData} />
                        <Summary mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} selectedButton={selectedButton} />
                    </div>
                </div>
            </div>
        </SummaryProvider>
    );
};
