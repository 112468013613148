import React from "react";

import styles from "./weeklyperiod.module.scss"

import { PrevWeekly } from "./prev/PrevWeekly";
import { ResultWeekly } from "./result/ResultWeekly";

export const WeeklyPeriod = ({ startDate, endDate, incrementId }) => {

    return (
        <div className={styles.weekly}>
            <p className={styles.weeklyTitle}> {startDate} ~ {endDate} </p>
            <PrevWeekly incrementId={incrementId} />
            <p className={styles.weeklyTitle}>주간 예측 결과</p>
            <ResultWeekly startDate={startDate} endDate={endDate} incrementId={incrementId} />
        </div>
    )
}