import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./reportlist.module.scss";
import { ReactComponent as Option } from "../../../../assets/images/home/home-reportlist.svg";

import { MyReportApi } from "../../../../api/report/MyReportApi";
import { DeleteReportApi } from "../../../../api/report/DeleteReportApi";
import { ChangeReportNameApi } from "../../../../api/report/ChangeReportNameApi";
import { DeleteModal } from "../modal/DeleteModal";
import { ModifyModal } from "../modal/ModifyModal";

export const ReportList = ({ username, handlePageAddClick }) => {
    const [data, setData] = useState(null);
    const [reportName, setReportName] = useState("");
    const [incrementId, setIncrementId] = useState("");
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modifyModal, setModifyModal] = useState(false);
    const [modifyModalIndex, setModifyModalIndex] = useState(null);
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await MyReportApi(username);
        setData(response);
        setLoading(false);
        // console.log(response);
    };
    
    useEffect(() => {
        fetchData();
    }, [username]);

    const handleOptionClick = (index) => {
        setIsModalOpen(isModalOpen === index ? null : index);
    };

    useEffect(() => {
        // 모달 외부 클릭 시 모달 닫기 처리
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(null);
            }
        };
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDeleteModal = (reportName, incrementId) => {
        setReportName(reportName);
        setIncrementId(incrementId);
        setDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const handleDeleteConfirm = async (incrementId) => {
        try {
            await DeleteReportApi(incrementId);
            setDeleteModal(false);
            fetchData();
        } catch (error) {
            console.error("보고서 삭제 중 오류 발생:", error);
        }
    };

    const handleModifyModal = (reportName, incrementId, index) => {
        setReportName(reportName);
        setIncrementId(incrementId);
        setModifyModalIndex(index);
    };

    const closeModifyModal = () => {
        setModifyModalIndex(null);
    };

    const handleModifyConfirm = async (incrementId, newReportName) => {
        try {
            await ChangeReportNameApi(incrementId, newReportName);
            setModifyModalIndex(null);
            fetchData();
        } catch (error) {
            console.error("보고서 이름 변경 중 오류 발생:", error);
            alert("같은 이름의 보고서가 이미 존재합니다.");
        }
    };

    const handleReportMove = (reportName, adAccountsId, mediaInfoId, incrementId, type) => {
        let path = "/report"
        if (mediaInfoId === 5) {
            path = "/report/naver/sa";
        }
        if (mediaInfoId === 89) {
            path = "/report/kakao/sa";
        }
        if (mediaInfoId === 100 && type === "sa") {
            path = "/report/google/sa";
        }
        if (mediaInfoId === 6) {
            path = "/report/naver/da";
        }
        if (mediaInfoId === 7) {
            path = "/report/kakao/da";
        }
        if (mediaInfoId === 100 && type === "da") {
            path = "/report/google/da";
        }
        handlePageAddClick();
        navigate(path , { state: { reportName: reportName, adAccountsId: adAccountsId, mediaInfoId: mediaInfoId, reportId: incrementId, reportType: type } });
    };

    return (
        <div className={styles.reportList}>
            {!loading && data?.reports ? (
                data.reports.map((report, index) => (
                <div key={index} className={styles.reportItem}>
                    <p onClick={() => handleReportMove(report.report_nm, report.ad_account_id, report.media_info_code, report.increment_id, report.type)} style={{cursor:"pointer"}}>{report.report_nm}</p>
                    <div onClick={() => handleOptionClick(index)} className={styles.optionIcon}>
                        <Option />
                        {isModalOpen === index && (
                            <div ref={modalRef} className={styles.modal}>
                                <button onClick={() => handleModifyModal(report.report_nm, report.increment_id, index)}>이름 수정하기</button>
                                <button onClick={() => handleDeleteModal(report.report_nm, report.increment_id)}>삭제</button>
                            </div>
                            
                        )}
                    </div>
                    {deleteModal && (
                        <DeleteModal reportName={reportName} incrementId={incrementId} onClose={closeDeleteModal} onConfirm={handleDeleteConfirm} />
                    )}
                    {modifyModalIndex === index && (
                        <ModifyModal 
                            reportName={report.report_nm} 
                            incrementId={report.increment_id} 
                            onClose={closeModifyModal} 
                            onConfirm={handleModifyConfirm} 
                        />
                    )}
                </div>
            ))
        ) : (
            <p>보고서를 불러오는 중입니다...</p>
        )}
        </div>
    );
};
