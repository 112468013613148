import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { NaverLoginApi } from "../../../api/socialLogin/NaverLoginApi";
import { getAccessToken, setAccessToken } from "../../../api/axios";
import { checkTokenAPI } from "../../../api/accounts/checkToken";
import { formatDate } from "../../../shared/navbar/utils/util";

import { loginUser } from "../../../store/UserSlice";
import { localDate } from "../../../store/dateSlice";

export const NaverCallback = () => {
    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const code = new URL(window.location.href).searchParams.get('code');

        const getToken = async (code) => {
            try {
                const response = await NaverLoginApi(code);

                if (response.status === 200) {
                    // Access Token 저장
                    localStorage.setItem("accessToken", response.access_token);
                    dispatch(loginUser({username:response.user_id}))
                    const initialStartDate = new Date();
                    const initialEndDate = new Date();
                    initialStartDate.setDate(initialStartDate.getDate() - 8);
                    initialEndDate.setDate(initialEndDate.getDate() - 2);
                    dispatch(
                    localDate({
                        startDate: formatDate(initialStartDate),
                        endDate: formatDate(initialEndDate),
                    })
                    );
                    setAccessToken(response.access_token);
                    // 리프레시 토큰 생성 예정
                    // 토큰 검증 및 로그인 처리
                    const accessToken = getAccessToken();
                    if (accessToken) {
                        const tokenCheckResponse = await checkTokenAPI();
                        if (tokenCheckResponse.status === 200) {
                            // 로그인 성공 시 홈으로 이동
                            navigate("/home");
                        } else {
                            console.error("토큰 검증 실패");
                            navigate("/"); // 토큰 검증 실패 시 로그인 페이지로 이동
                        }
                    } else {
                        console.error("액세스 토큰 없음");
                        navigate("/"); // 액세스 토큰이 없는 경우 로그인 페이지로 이동
                    }
                } else if (response.status === 201) {
                    // 추가 정보가 필요할 때 회원가입 페이지로 이동
                    navigate("/social/signup", { state: { username: response.user_id, media: response.media } });
                }
            } catch (err) {
                console.log(err);
                navigate("/");
            }
        };

        if (code) {
            getToken(code);
        }
    }, [location.search, navigate]);
    

    return(
        <></>
    );
};