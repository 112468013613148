import React, { createContext, useState } from "react";

export const SummaryContext = createContext();

export const SummaryProvider = ({ children }) => {
    // 총 광고비용 (keyword, cost 페이지)
    const [cost, setCost] = useState(null); 
    // 총 클릭수(방문자수) (keyword 페이지)
    const [click, setClick] = useState(null); 
    // 총 매출(매출전환액) (keyword 페이지)
    const [amount, setAmount] = useState(null); 
    // 전환수(구매자수) (keyword 페이지)
    const [conversion, setConversion] = useState(null); 
    // 전환율 (keyword 페이지)
    const [conversionRate, setConversionRate] = useState(null); 
    // 전환당 비용 (keyword 페이지)
    const [conversionCost, setConversionCost] = useState(null); 
    // 현재 날짜와 이전 날짜 클릭당 비용 증감율(variance: 값, operator:증감 여부) (cost 페이지)
    const [clickCostVar, setClickCostVar] = useState({variance: null, operator: null}); 
    // 현재 날짜와 이전 날짜 클릭률 증감율(variance: 값, operator:증감 여부) (click 페이지)
    const [clickRateVar, setClickRateVar] = useState({variance: null, operator: null}); 
    // 광고수익률이 가장 높은 키워드 3개 (keyword 페이지)
    const [topKeyword, setTopKeyword] = useState({convAmount: null, convCount: null, roas: null}); 
     // 클릭률, 매출(매출전환액)이 가장 높은 요일 (keyword, click 페이지)
    const [highDay, setHighDay] = useState({clickRate: null, amount: null, cost: null});
    // 클릭률, 매출(매출전환액)이 가장 낮은 요일 (keyword, click 페이지)
    const [lowDay, setLowDay] = useState({clickRate: null, amount: null, cost: null}); 
    // PC/MO 캠페인 비교 (keyword, click, cost 페이지)
    const [deviceCampaignKeyword, setDeviceCampaignKeyword] = useState({roas: [null], convAmount: [null]});
    const [deviceCampaignClick, setDeviceCompClick] = useState({clickRate: [null]});
    const [deviceCampaignCost, setDeviceCompCost] = useState({cost: null});
    // 광고수익률이 가장 높은 캠페인 (click, cost 페이지)
    const [topCampaign, setTopCampaign] = useState({roas: null, clickRate: null}); 
    // 이전 기간 대비 클릭률이 가장 높게 증감한 키워드 
    const [clickRateComp, setClickRateComp] = useState({highKeyword: null, lowKeyword: null}); 
    // 광고수익률(roas), 평균노출순위(avgRank), 전환당 비용(convCost)가 가장 낮은 키워드
    const [lowKeyword, setLowKeyword] = useState({roas: null, avgRank: [null], convCost: null}); 
    // 전환당 비용(convCost)가 가장 높은 키워드
    const [highKeyword, setHighKeyword] = useState({convCost: null}); 

    return (
        <SummaryContext.Provider value={{
            cost, setCost,
            click, setClick,
            amount, setAmount,
            conversion, setConversion,
            conversionRate, setConversionRate,
            conversionCost, setConversionCost,
            clickCostVar, setClickCostVar,
            clickRateVar, setClickRateVar,
            topKeyword, setTopKeyword,
            highDay, setHighDay,
            lowDay, setLowDay,
            deviceCampaignKeyword, setDeviceCampaignKeyword,
            deviceCampaignClick, setDeviceCompClick,
            deviceCampaignCost, setDeviceCompCost,
            topCampaign, setTopCampaign,
            clickRateComp, setClickRateComp,
            lowKeyword, setLowKeyword,
            highKeyword, setHighKeyword
        }}>
            {children}
        </SummaryContext.Provider>
    )
}