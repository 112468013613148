import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/campaignclick.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { ClickCampaignGaugeChartApi, ClickCampaignLineChartApi } from '../../../../../api/report/click/ClickCampaignChartApi';

import { CampaignGauge } from '../../../../utils/chart/CampaignGauge';
import { CampaignLine } from '../../../../utils/chart/CampaignLine';
import Loading from '../../../../utils/loading/Loading';

export const CampaignClick = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [gaugeData, setGaugeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [metricType, setMetricType] = useState('tot_impression');
    const [timeUnit, setTimeUnit] = useState('day');
    const { setTopCampaign } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const handleResponse = (response) => {
        if (!response || !response.data || response.data.length === 0) return;
        const highestClickRateCampaign = response.data.reduce((prev, current) => {
            return (prev.click_rate > current.click_rate) ? prev : current;
        });
        setTopCampaign({clickRate: highestClickRateCampaign.campaign_name});
    }

    useEffect(() => {
        const fetchData = async () => {
            const gaugeResponse = await ClickCampaignGaugeChartApi(mediaName(), startDate, endDate, adAccountsId);
            handleResponse(gaugeResponse);
            setGaugeData(gaugeResponse);
            const campaignId = gaugeResponse.data.map(item => item.campaign_id);
            let lineResponse = null;
            if (campaignId.length > 0) {
                lineResponse = await ClickCampaignLineChartApi(mediaName(), startDate, endDate, adAccountsId, campaignId, timeUnit);
            }
            setData(lineResponse);
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, metricType, timeUnit]);

    if (loading) {
        return (
            <div className={styles.campaignClick}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <div className={styles.campaignClick}>
            <div className={styles.campaignClickTitle}>캠페인별 노출/클릭</div>
            <div className={styles[`campaignClickSelect${mediaInfoId}`]}>
                <select value={metricType} onChange={(e) => setMetricType(e.target.value)}>
                    <option value="tot_impression">노출수</option>
                    <option value="tot_click">클릭수</option>
                    <option value="click_rate">클릭률</option>
                </select>
                <select value={timeUnit} onChange={(e) => setTimeUnit(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.campaignClickChart}>
                <CampaignGauge data={gaugeData} selectedData={metricType} mediaInfoId={mediaInfoId} />
                <CampaignLine data={data.data} selectedData={metricType} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    );
};