import React from "react";

import styles from "../scss/mediaperformance.module.scss"

import { PerformanceLine } from "./PerformanceLine";
import { PerformanceMediaCard } from "./PerformanceMediaCard";

import { InfoIcon } from "../../../utils/info/InfoIcon";


export const MediaPerformance = ({ startDate, endDate }) => {
    const message = () => {
        return (
            <>
            광고 매체별로 예측된 매출액입니다.
            </>
        )
    }

    return (
        <div className={styles.performance}>
            <div className={styles.performanceTxt}>
                <p>매체별 성과</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <PerformanceLine />
            <PerformanceMediaCard startDate={startDate} endDate={endDate} />
        </div>
    )
}








