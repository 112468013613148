import React, { useState, useEffect } from 'react';

import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

import styles from '../scss/keywordtrend.module.scss';

import { KeywordTrendApi } from '../../../../../api/report/keyword/KeywordTrendApi';

import Loading from '../../../../utils/loading/Loading';
import { InfoIcon } from '../../../../utils/info/InfoIcon';

// getRotationDegree 함수 정의
function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
}

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

export const KeywordTrend = ({ width = 300, height = 250, mediaInfoId, adAccountsId, startDate, endDate }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [spiralType, setSpiralType] = useState('archimedean');
    const [withRotation, setWithRotation] = useState(false);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordTrendApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <div className={styles.keywordTrend}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const words = data.data
        .filter(item => item.keyword_name !== 'nan') // 'nan' 제외
        .map(item => ({
            text: item.keyword_name,
            value: parseInt(item.count, 10) // count를 정수로 변환
        }));

    const fontScale = scaleLog({
        domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
        range: [10, 100],
    });
    const fontSizeSetter = (datum) => fontScale(datum.value);

    const message = () => {
        return (
            <>
            상단 버튼을 클릭하여 원하는 항목으로 표를 커스텀하고 엑셀 파일로 다운 받을 수 있습니다.
            </>
        )
    }

    return (
        <div className={styles.keywordTrend}>
            <div className={styles.keywordTrendTitle}>
                <p>키워드 추세</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.wordcloud}>
                <Wordcloud
                    words={words}
                    width={width}
                    height={height}
                    fontSize={fontSizeSetter}
                    font={'Pretendard'}
                    padding={2}
                    spiral={spiralType}
                    rotate={withRotation ? getRotationDegree : 0}
                    random={() => 0.5}
                >
                    {(cloudWords) =>
                        cloudWords.map((w, i) => (
                            <Text
                                key={w.text}
                                fill={colors[i % colors.length]}
                                textAnchor={'middle'}
                                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                fontSize={w.size}
                                fontFamily={w.font}
                            >
                                {w.text}
                            </Text>
                        ))
                    }
                </Wordcloud>
            </div>
        </div>
    )
}