import { useEffect, useState } from "react";
import { GetMediaLinkedAPI, UnlinkDataApi } from "../../../../api/dataCenter/MediaLinkApi";
import styles from "../scss/box/medialinkedlistbox.module.scss"
import { FaceBookAccountsApi } from "../../../../api/socialLogin/FaceBookApi";
import { useNavigate } from "react-router-dom";
import Loading from "../../../utils/loading/Loading";
import { GoogleAccountsApi } from "../../../../api/socialLogin/GoogleApi";
import { KakaoKeywordTargetApi } from "../../../../api/socialLogin/KakaoApi";
import { KakaoMomentTargetApi } from "../../../../api/socialLogin/KakaoMomentApi";

const MediaLinkedListBox = ({selectedLink}) => {
    const [linkedList, setLinkedList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accountLoading, setAccountLoading] = useState(false);
    useEffect(() => {
        const getToken = async () => {
            try {
                const response = await GetMediaLinkedAPI(selectedLink);
                // console.log(response.media_link_data);
                setLinkedList(response.media_link_data);
                setLoading(false);
                
            } catch (err) {
                console.log(err);
            }
        };
        getToken()
    }, [])
    const accountsInfoClick = async ({incrementId, media_info_code}) => {
        setAccountLoading(true)
        switch (media_info_code) {
            case 10: // 페이스북
                try{
                    const response = await FaceBookAccountsApi(incrementId)
                    setAccountLoading(false)
                    if (response.fb_ids && response.fb_name && response.media_link_id) {
                        localStorage.setItem("facebook", JSON.stringify(response));
                        window.location.href = "/datalink/facebook/modal";
                    } else {
                        console.error("필수 데이터가 없습니다. 데이터를 저장할 수 없습니다.");
                    }
                    
                } catch (err) {
                    console.error(err);
                }
                break;
            case 5:
                setAccountLoading(false)
                return "네이버";
            case 89: // 카카오 키워드
                try{
                    const response = await KakaoKeywordTargetApi(incrementId)
                    setAccountLoading(false)
                    // console.log(`test : ${response.data.kakao_ids}`);
                    
                    if(response.data.kakao_ids && response.data.kakao_names && response.data.media_link_id) {
                        localStorage.setItem("kakao-keyword", JSON.stringify(response.data));
                        window.location.href= "/datalink/kakao/modal";
                    } else {
                        console.error("필수 데이터가 없습니다. 데이터를 저장할 수 없습니다.")
                    }
                } catch (err) {
                    console.error(err)
                }
                break;
            case 100: // 구글
                try{
                        const response = await GoogleAccountsApi(incrementId)
                        setAccountLoading(false)
                        if (response.google_ids && response.google_name && response.media_link_id) {
                            localStorage.setItem("googleads", JSON.stringify(response));
                            window.location.href = "/datalink/google/modal";
                        } else {
                            console.error("필수 데이터가 없습니다. 데이터를 저장할 수 없습니다.");
                        }
                        // console.log(response);
                        
                } catch (err) {
                    console.error(err);
                }
                break;
            case 101:
                return "GA4";
            case 7: // 카카오모먼트
                try{
                    const response = await KakaoMomentTargetApi(incrementId)
                    setAccountLoading(false)
                    // console.log(`test : ${response.data.kakao_ids}`);
                    
                    if(response.data.kakao_ids && response.data.kakao_names && response.data.media_link_id) {
                        localStorage.setItem("kakao-moment", JSON.stringify(response.data));
                        window.location.href= "/datalink/moment/modal";
                    } else {
                        console.error("필수 데이터가 없습니다. 데이터를 저장할 수 없습니다.")
                    }
                } catch (err) {
                    console.error(err)
                }
                break;
            default:
                return "알 수 없는 플랫폼"; // 기본값 설정
        }
        
    }
    const deleteClick = async ({incrementId}) => {
        try {
            
            const response = await UnlinkDataApi(incrementId); 
            
            setLinkedList(response.media_link_data);
        } catch (error) {
            console.error("연결 해지 중 오류 발생:", error);
        }

    }

    const platformName = ((media_info_code) => {
        switch (media_info_code) {
            case 10:
                return "페이스북";
            case 5:
                return "네이버";
            case 89:
                return "카카오키워드";
            case 100:
                return "구글광고";
            case 101:
                return "GA4";
            case 7:
                return "카카오모먼트";
            default:
                return "알 수 없는 플랫폼"; // 기본값 설정
        }
    })

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }
    if (accountLoading) {
        return (
            <>
                <Loading />
            </>
        )
    }

    // console.log(linkedList)

    return(
        <>  
            
            {/* testData -> linkedList */}
            {linkedList && linkedList.length > 0 ? (
                <div className={linkedList[0].media_info_code === 5 ? styles.listNaverBox : styles.listBox}>
                    {/* linkedList 데이터를 div에 뿌려줌 */}
                    {linkedList.map((item, index) => (
                        <div key={index} className={styles.linkedDataBox}>
                            <div className={styles.gridContainer}>
                            <div className={styles.itemInfoBox}>
                                <p className={styles.mediaUserName}>계정명: {item.media_profile_json.user_name}</p>
                                <p className={styles.mediaName}>
                                    {
                                        platformName(item.media_info_code)
                                    }
                                </p>
                            </div>
                                <div className={styles.itemButtonBox}>
                                    <div className={styles.infoButton} onClick={() => accountsInfoClick({incrementId: item.increment_id, media_info_code: item.media_info_code})}>
                                        광고계정보기
                                    </div>
                                    <div className={styles.deleteButton} onClick={() => deleteClick({incrementId: item.increment_id})}>
                                        삭제
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
            ) : (
                null
            )}
        </>
    );
}
export default MediaLinkedListBox