import React, { useState, useEffect } from 'react';

import styles from "../scss/datrendchart.module.scss"
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-trend-kakao-labels.svg"
import { ReactComponent as NaverLabels } from "../../../../../assets/images/report/report-trend-naver-labels.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-trend-google-da-labels.svg"

import { DaCostTrendChartApi } from '../../../../../api/reportDA/cost/DaCostTrendChartApi';

import { BarLine } from "../../../../utils/chart/BarLine";
import Loading from '../../../../utils/loading/Loading';

export const DaTrendChart = ({
    mediaInfoId,
    adAccountsId, 
    startDate, 
    endDate,
    selectedValue,
    setSelectedValue,
    reportType}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    // const [selectedValue, setSelectedValue] = useState("day"); // 선택된 값 상태 추가

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        } else if (mediaInfoId === 7) {
            return "kakao";
        }
        return "unknown";
    }

    const labels = () => {
        if (mediaInfoId === 6) {
            return <NaverLabels />
        } else if (mediaInfoId === 7) {
            return <KakaoLabels />
        } else if (mediaInfoId === 100) {
            return <GoogleLabels />
        }
    } 

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostTrendChartApi(mediaName(), startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, selectedValue])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    // const testData = {data:[
    //     {conversion_cost: 1230, conversion_rate: 149.61 ,date: "2024-10-13" ,roas: 0.08,tot_conversion_amount: 3067,tot_conversion_count: 3067,tot_cost: 3772810},
    //     {conversion_cost: 2422,conversion_rate: 135.81,date: "2024-10-14",roas: 0.04,tot_conversion_amount: 5617,tot_conversion_count: 5617,tot_cost: 13609440},
    //     {conversion_cost: 2694,conversion_rate: 124.31,date: "2024-10-15",roas: 0.04,tot_conversion_amount: 4720,tot_conversion_count: 4720,tot_cost: 12717900},
    //     {conversion_cost: 2561,conversion_rate: 126.63,date: "2024-10-16",roas: 0.04,tot_conversion_amount: 4489,tot_conversion_count: 4489,tot_cost: 11500570},
    //     {conversion_cost: 2932,conversion_rate: 112.32,date: "2024-10-17",roas: 0.03,tot_conversion_amount: 3648,tot_conversion_count: 3648,tot_cost: 10696640},
    //     {conversion_cost: 2796,conversion_rate: 123.71,date: "2024-10-18",roas: 0.04,tot_conversion_amount: 3804,tot_conversion_count: 3804,tot_cost: 10637990},
    //     {conversion_cost: 1564,conversion_rate: 129.95,date: "2024-10-19",roas: 0.06,tot_conversion_amount: 2473,tot_conversion_count: 2473,tot_cost: 3868140}
    // ]}
                
    return (
        <div className={styles.daTrendChart}>
            <div className={styles.daTrendChartTitle}>전환 추세</div>
            <div className={styles[`daTrendChartSelect${mediaInfoId}`]}>
                <div style={{height: "17px"}}>{labels()}</div>
                <select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.daTrendChartChart}>
                <BarLine data={data} lineLabel="전환 매출액" barLabel="광고비" mediaInfoId={mediaInfoId} reportType={reportType}/>
            </div>
        </div>
    )
}
