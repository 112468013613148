import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import styles from "../scss/reportcreatemodal.module.scss";

import { AdAccountsApi } from "../../../../api/dataCenter/AdAccountsApi";

const mediaToIdMap = {
    total: [5, 7, 10, 89, 100, 101],
    google_sa: [100],
    google_da: [100],
    ga4: [101],
    naver_sa: [5],
    kakao_keyword: [89],
    kakao_moment: [7],
    facebook: [10],
    cost_report: [5, 7, 10, 89, 100, 101],
    preiod_report: [5, 7, 10, 89, 100, 101],
    // 필요한 대로 추가
};

const mediaNames = {
    5: "네이버 광고",
    7: "카카오 광고",
    89: "카카오 키워드",
    10: "페이스북 광고",
    100: "구글 광고",
    101: "GA4 광고",
    // 필요한 대로 추가
};

export const AdvLink = ({ media, handleSelectOption, selectedOption }) => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [newFilteredData, setNewFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState({});
    const username = useSelector((state) => state.user.username);
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await AdAccountsApi(username);
            setData(response.data);
            setLoading(false);
            // console.log(response);
        };

        fetchData();
    }, [username]);

    useEffect(() => {
        if (data && media) {
            const mediaInfoIds = mediaToIdMap[media] || [];
            const filtered = data.filter((item) => mediaInfoIds.includes(item.media_info_code));
            setFilteredData(filtered);

            const uniqueIds = [...new Set(filtered.map((item) => item.media_info_code))];
            setNewFilteredData(uniqueIds);
        }
    }, [data, media]);

    useEffect(() => {
        if (newFilteredData.length > 0) {
            setIsOpen((prevState) => ({
                ...prevState,
                [newFilteredData[0]]: true,
            }));
        }
    }, [newFilteredData]);

    const toggleDropdown = (id) => {
        setIsOpen((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    return (
        <div className={styles.advLinkBox}>
            {newFilteredData.map((id) => (
                <div key={id} className={styles.advLink}>
                    <div className={styles.advLinkSelect} onClick={() => toggleDropdown(id)}>
                        <span>{mediaNames[id]}</span>
                    </div>
                    {isOpen[id] && (
                        <div className={styles.dropdownList}>
                            {filteredData.length > 0 ? (
                                filteredData
                                    .filter((item) => item.media_info_code === id) // 해당 id에 맞는 데이터만 필터링
                                    .map((item) => (
                                        <div
                                            key={item.media_info_code}
                                            className={`${styles.dropdownItem} ${
                                                selectedOption[id] && selectedOption[id].link_data_nm === item.link_data_nm
                                                    ? styles.selectedItem
                                                    : ""
                                            }`}
                                            onClick={() => handleSelectOption(id, item)}
                                        >
                                            <label className={styles.checkboxContainer}>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        selectedOption[id] &&
                                                        selectedOption[id].link_data_nm === item.link_data_nm
                                                    }
                                                    readOnly
                                                />
                                                <span className={styles.customCheckbox}></span>
                                            </label>
                                            {item.link_data_nm || item.account_nm}
                                        </div>
                                    ))
                            ) : (
                                <div className={styles.dropdownItem}>해당 미디어에 대한 데이터가 없습니다.</div>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
