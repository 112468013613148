import React, { useState } from 'react'

import styles from './reporttable.module.scss'

import CustomPagination from '../pagination/Pagination'
import {ReactComponent as SortMark} from '../../../assets/images/share/sort.svg'
import {ReactComponent as UpSortMark} from '../../../assets/images/share/upsort.svg'
import {ReactComponent as DownSortMark} from '../../../assets/images/share/downsort.svg'

import { sortItems } from './SortItems'

export const FunnelTable = ({ 
        defaultHeaders, 
        headers, 
        items, 
        totalItems, 
        pageData, 
        handlePageChange, 
        currentPage, 
        mediaInfoId, 
        adLevel, 
        onCampaignClick, 
        onGroupClick,
        setOrderBy,
        defaultOrderBy,
        reportType 
    }) => {
    const combinedItems = [totalItems, ...items];
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });

    const handleCampaignClick = (campaignId) => {
        if (typeof onCampaignClick === 'function') {
            onCampaignClick(campaignId);
        }
    };

    const handleGroupClick = (groupId) => {
        if (typeof onGroupClick === 'function') {
            onGroupClick(groupId);
        }
    };

    const handleSort = (key) => {
        sortItems(key, sortConfig, setSortConfig, setOrderBy, defaultOrderBy);
    };

    // console.log(combinedItems)

    return (
        <>
            <div className={adLevel === '소재' ? styles.creativeTable : styles.funnelTable}>
                <div className={styles.tableHeader}>
                    <div className={adLevel === '소재' ? styles.creativeHeaderRow : styles.tableHeaderRow}>
                        {Array.isArray(defaultHeaders) && defaultHeaders.length > 0 && (
                            defaultHeaders.map((item, index) => (
                                <div key={item} onClick={() => handleSort(item)}>
                                    {item}
                                    <span>
                                        {sortConfig.key === item ? (sortConfig.direction === 'ascending' ? <UpSortMark/> : sortConfig.direction === 'descending' ? <DownSortMark/> : <SortMark />) : <SortMark />}
                                    </span> 
                                </div>
                            ))
                        )}
                        {Object.keys(headers).filter(key => headers[key]).map(key => (
                            <div key={key} onClick={() => handleSort(key)}>
                                {key}
                                <span>
                                    {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? <UpSortMark/> : sortConfig.direction === 'descending' ? <DownSortMark/> : <SortMark />) : <SortMark />}
                                </span> 
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {combinedItems.map((row, index) => (
                        <div key={index} className={adLevel === '소재' ? styles.creativeBodyRow : styles.tableBodyRow}>
                            {defaultHeaders.map((header, headerIndex) => {
                                const displayValue = typeof row[header] === 'number' 
                                    ? (header === "광고비" ? `₩${row[header].toLocaleString()}` : row[header].toLocaleString()) 
                                    : row[header];
                                    if (
                                        adLevel === "소재" &&
                                        header === "소재" &&
                                        typeof row[header] === 'string' &&
                                        (row[header].endsWith('.jpg') || row[header].endsWith('.png'))
                                    ) {
                                        return (
                                            <div key={header} className={styles.creativeTxt}>
                                                <p>{displayValue}</p>
                                                <img src={row[header]} alt="소재 이미지" style={{ width: '124px', height: '80px' }} />
                                            </div>
                                        );
                                    }
                                return (
                                    <div 
                                        key={`default-${headerIndex}`} 
                                        className={index === 0 || adLevel === '키워드' || adLevel === '소재' ? styles.firstLinkTxt : styles.linkTxt} 
                                        onClick={header === "캠페인" ? () => handleCampaignClick(row.campaignId) : header === "광고그룹" ? () => handleGroupClick(row.groupId) : undefined}
                                    >
                                        <p>{displayValue}</p>
                                        <span className={styles.tooltipTxt}>{displayValue}</span>
                                    </div>
                                );
                            })}
                            {Object.keys(headers).filter(key => headers[key]).map(key => {
                                const displayValue = typeof row[key] === 'number' 
                                ? (key === "전환 매출액" ? `₩${row[key].toLocaleString()}` 
                                    : (key === "클릭당 비용" ? `₩${row[key].toLocaleString()}` 
                                    : (["클릭률", "전환율", "광고수익률"].includes(key) ? `${row[key].toFixed(2)}%` : row[key].toLocaleString())))
                                : row[key];
                                return (
                                    <div key={key}>
                                        <p>{displayValue}</p>
                                        <span className={styles.tooltipTxt}>{displayValue}</span>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <CustomPagination 
                    total={pageData.count} 
                    views={pageData.page_size}
                    handlePageChange={handlePageChange}
                    page={currentPage}
                    mediaInfoId={mediaInfoId}
                    reportType={reportType}
                />
            </div>
        </>
    );
};