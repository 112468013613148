import React, { useState, useEffect } from 'react';

import styles from '../scss/clicktrend.module.scss';
import { ReactComponent as KakaoLabels } from "../../../../../assets/images/report/report-trend-kakao-labels.svg"
import { ReactComponent as NaverImpLabels } from "../../../../../assets/images/report/report-trend-imp-naver-labels.svg"
import { ReactComponent as NaverClickLabels } from "../../../../../assets/images/report/report-trend-click-naver-labels.svg"
import { ReactComponent as GoogleLabels } from "../../../../../assets/images/report/report-trend-google-labels.svg"

import { ClickTrendApi } from '../../../../../api/report/click/ClickTrendApi';

import { BarLine } from '../../../../utils/chart/BarLine';
import Loading from '../../../../utils/loading/Loading';

export const ClickTrend = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("day");
    const [selectedData, setSelectedData] = useState("tot_impression");

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    }

    const labels = () => {
        if (mediaInfoId === 5 && selectedData === "tot_impression") {
            return <NaverImpLabels />
        } else if (mediaInfoId === 5 && selectedData === "tot_click") {
            return <NaverClickLabels />
        } else if (mediaInfoId === 89) {
            return <KakaoLabels />
        } else if (mediaInfoId === 100) {
            return <GoogleLabels />
        }
    };

    const barLabel = () => {
        if (selectedData === "tot_impression") {
            return "노출수"
        } else if (selectedData === "tot_click") {
            return "클릭수"
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await ClickTrendApi(mediaName(), startDate, endDate, adAccountsId, selectedValue); // 선택된 값 전달
            setData(response);
            setLoading(false);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate, selectedValue])

    if (loading) {
        return (
            <div className={styles.clickTrend}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    return (
        <div className={styles.clickTrend}>
            <div className={styles.clickTrendTitle}>노출/클릭 추세</div>
            <div className={styles[`clickTrendSelect${mediaInfoId}`]}>
                <div className={styles.trendBtn}>
                    <button 
                        className={selectedData === "tot_impression" ? styles[`activeTrend${mediaInfoId}`] : styles[`inactiveTrend${mediaInfoId}`]}
                        onClick={() => setSelectedData("tot_impression")}
                    >
                        노출수
                    </button>
                    <button 
                        className={selectedData === "tot_click" ? styles[`activeTrend${mediaInfoId}`] : styles[`inactiveTrend${mediaInfoId}`]}
                        onClick={() => setSelectedData("tot_click")}
                    >
                        클릭수
                    </button>
                </div>
                <select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                    <option value="day">일자별</option>
                    <option value="week">주별</option>
                    <option value="month">월별</option>
                </select>
            </div>
            <div className={styles.chartLabels}>{labels()}</div>
            <div className={styles.clickTrendChart}>
                <BarLine data={data} lineLabel="전환 매출액" barLabel={barLabel()} mediaInfoId={mediaInfoId} value={selectedData} />
            </div>
        </div>
    );
}