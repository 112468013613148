import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/dayimpression.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { CostDayImpressionApi } from '../../../../../api/report/cost/CostDayImpressionApi';

import { YaxisBarChart } from '../../../../utils/chart/YaxisBarChart';
import Loading from '../../../../utils/loading/Loading';

export const DayImpression = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("cost"); // 선택된 값 상태 추가
    const { setHighDay, setLowDay } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const handleResponse = (response) => {
        // response에서 click_rate와 day_week를 추출
        const clickRateDate = response.data.map(item => ({
            day_week: item.day_week,
            cost_click: item.cost_click
        }));
    
        // click_rate가 가장 높은 요일과 가장 낮은 요일 찾기
        const highestDay = clickRateDate.reduce((prev, current) => 
            (prev.cost_click > current.cost_click) ? prev : current
        );
        const lowestDay = clickRateDate.reduce((prev, current) => 
            (prev.cost_click < current.cost_click) ? prev : current
        );
    
        // 상태 업데이트
        setHighDay({ cost: highestDay.day_week });
        setLowDay({ cost: lowestDay.day_week });
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostDayImpressionApi(mediaName(), startDate, endDate, adAccountsId); // 선택된 값 전달
            setData(response);
            setLoading(false);

            handleResponse(response);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <div className={styles.dayImpression}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const chartData = data.data.map(item => ({
        day: item.day_week, // day_week 값을 사용
        value: selectedValue === "impression" ? item.impression_cost :
               selectedValue === "click" ? item.cost_click :
               selectedValue === "conversion" ? item.conversion_cost :
               item.tot_cost
    }));

    const barLabel = () => {
        if (selectedValue === "cost") {
            return "총 광고비";
        } else if (selectedValue === "impression") {
            return "노출당 비용";
        } else if (selectedValue === "click") {
            return "클릭당 비용";
        } else if (selectedValue === "conversion") {
            return "전환당 비용";
        }
    }

    return (
        <div className={styles.dayImpression}>
            <div className={styles.dayImpressionTitle}>
                <p>요일별 광고비</p>
                <div className={styles[`dayImpressionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="cost">총 광고비</option>
                        <option value="impression">노출당 비용</option>
                        <option value="click">클릭당 비용</option>
                        <option value="conversion">전환당 비용</option>
                    </select>
                </div>
            </div>
            <div className={styles.dayImpressionContent}>
            <YaxisBarChart data={chartData} barLabel={barLabel()} mediaInfoId={mediaInfoId} />
            </div>
        </div>
    )
}
