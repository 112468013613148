import React, { useState } from "react";
import Select, { components } from "react-select";

import styles from "../scss/predcondition.module.scss";
import { ReactComponent as Naver } from "../../../../assets/images/share/naver.svg";
import { ReactComponent as Kakao } from "../../../../assets/images/share/kakao.svg";
import { ReactComponent as Google } from "../../../../assets/images/share/google.svg";
import { ReactComponent as Meta } from "../../../../assets/images/share/meta.svg";

import { InfoIcon } from "../../../utils/info/InfoIcon";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="16" // 화살표 너비
                height="10" // 화살표 높이
                viewBox="0 0 16 10"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0L8 10L16 0"
                    fill="#00A0A0" // 화살표 내부 색상
                />
            </svg>
        </components.DropdownIndicator>
    );
};


export const PredCondition = ({ startDate, endDate }) => {
    const [target, setTarget] = useState("conv_count");
    const [percent, setPercent] = useState({})

    const targetOptions = [
        { label: "전환수", value: "conv_count" },
        { label: "상품 매출", value: "conv_amount" },
        { label: "광고수익률", value: "roas" },
    ];

    const handleChange = (value) => {
        setTarget(value);
    };

    const mediaBox = [
        {name: "네이버", mark: <Naver />},
        {name: "카카오", mark: <Kakao />},
        {name: "구글광고", mark: <Google />},
        {name: "메타", mark: <Meta />},
    ]

    const changePer = [
        { label: "-30%", value: "decrease_thr" },
        { label: "-20%", value: "decrease_two" },
        { label: "-10%", value: "decrease_one" },
        { label: "0", value: "zero" },
        { label: "+10%", value: "increase_one" },
        { label: "+20%", value: "increase_two" },
        { label: "+30%", value: "increase_thr" },
        { label: "+40%", value: "increase_four" },
        { label: "+50%", value: "increase_five" },
    ]

    const handlePercentChange = (mediaName, value) => {
        setPercent((prev) => ({
            ...prev,
            [mediaName]: value,
        }));
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '94px',
            height: '28px',
            minHeight: '28px',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 800,
            color: "#8C8C8C",
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            '&:hover': {
                borderColor: state.isFocused ? "#BDC2C7" : "#BDC2C7",
            },
            display: 'flex', // Flexbox 적용
            justifyContent: 'space-between', // 숫자와 화살표를 양쪽 끝으로 정렬
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 4px', // 숫자 주변 간격 조정
            justifyContent: 'flex-end', // 숫자 위치를 화살표 방향으로 이동
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: "8px 6px", // 화살표 주변 여백
        }),
        indicatorSeparator: () => ({
            display: 'none', // 화살표 왼쪽 선 제거
        }),
        menu: (provided) => ({
            ...provided,
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 800,
            color: "#8C8C8C",
            borderRadius: "5px",
        }),
        option: (provided, state) => ({
            ...provided,
            width: '95%',
            marginLeft: '3px',
            marginRight: '3px',
            fontFamily: 'Pretendard',
            fontSize: '13px',
            fontWeight: 800,
            backgroundColor: state.isSelected ? 'rgba(0, 160, 160, 0.2)' : 'white',
            color: state.isSelected ? '#00A0A0' : '#8C8C8C',
            '&:hover': {
                backgroundColor: 'rgba(0, 160, 160, 0.2)',
                color: '#00A0A0',
            },
        }),
        menuList: (provided) => ({
            ...provided,
            paddingRight: '5px',
            '::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
                marginRight: '5px',
            },
            '::-webkit-scrollbar-thumb': {
                background: '#D9D9D9',
                borderRadius: '5px',
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#8C8C8C',
            },
            '::-webkit-scrollbar-track': {
                background: '#FFF',
                paddingRight: '5px',
            },
        }),
    };

    const firstMsg = () => {
        return (
            <>
            광고 매체에서 달성하고자 하는 목표(전환수, 매출, ROAS) 를 선택합니다.
            </>
        )
    }

    const secondMsg = () => {
        return (
            <>
            다음 주에 사용할 광고비를 얼마나 변경할지 설정합니다.
            </>
        )
    }


    return (
        <div className={styles.predCondition}>
            <h3>예측 조건 설정</h3>
            <h5>다음 주 광고 예산을 매체별로 조정하고, 목표 전환을 설정하여 광고 성과를 예측해 보세요.</h5>
            <div className={styles.conditionBox}>
                <div className={styles.adTarget}>
                    <div className={styles.infoBox}>
                        <p>1. 광고 목표를 선택해주세요.</p>
                        <InfoIcon message={firstMsg()} className="infoTxtBox2" margin="210px" />
                    </div>
                    <div className={styles.radioGroup}>
                        {targetOptions.map((option) => (
                            <label key={option.value} className={styles.radioOption}>
                                <input
                                    type="radio"
                                    value={option.value}
                                    checked={target === option.value}
                                    onChange={() => handleChange(option.value)}
                                />
                                <span className={styles.radioCustom}></span>
                                <span className={styles.radioLabel}>{option.label}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={styles.adCostChange}>
                    <div className={styles.infoBox}>
                            <p>2. 광고비 변경</p>
                            <InfoIcon message={secondMsg()} className="infoTxtBox2" margin="110px" />
                    </div>
                    <div className={styles.mediaBox}>
                        <div className={styles.mediaContainer}>
                            {mediaBox.map((media) => (
                                <div className={media.name === "메타" ? styles.noDataBox : styles.changeBox}>
                                    <div className={styles.mediaImg}>{media.mark}</div>
                                    <p className={styles.mediaName}>{media.name} 광고비</p>
                                    <p className={styles.prevCost}>지난주 광고비</p>
                                    <Select
                                        options={changePer}
                                        value={changePer.find(
                                            (option) => option.value === (percent[media.name] || "zero")
                                        )}
                                        onChange={(selectedOption) =>
                                            handlePercentChange(media.name, selectedOption.value)
                                        }
                                        styles={customStyles}
                                        placeholder="변경 비율 선택"
                                        components={{ DropdownIndicator }}
                                    />
                                    {media.name === "메타" ? (
                                        <div className={styles.noDataTxt}>
                                            <p>메타 정보 없음</p>
                                            <p>현재 준비중인 기능으로 25년 2월부터 연결 가능합니다.</p>
                                        </div>
                                    ):( 
                                        <></>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={styles.totalBox}>
                            <p className={styles.totalBoxTxt}>변경된 광고비 총합</p>
                            <p className={styles.totalCost}>4,000원</p>
                            <p className={styles.totalBoxTxt}>광고비 증감률</p>
                            <p className={styles.totalCost}>-</p>
                            <button className={styles.predBtn}>예측하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}