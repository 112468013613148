import React, { useState, useEffect } from "react";

import styles from "../scss/dacamptypeanalysis.module.scss"

import { DaCampTypeApi, DaCampTypeTotalApi } from "../../../../../api/reportDA/campaign/DaCampTypeApi";

import Loading from "../../../../utils/loading/Loading";
import { ReportDaTable } from "../../../../utils/table/ReportDaTable";
import { DaSingleBar } from "../../../../utils/chart/DaBarChart";


export const DaCampTypeAnalysis = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("tot_conversion_amount");
    const [label, setLabel] = useState("전환 매출액")

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "google"; 
        } else if (mediaInfoId === 7) {
            return "kakao";
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCampTypeApi(mediaName(), startDate, endDate, adAccountsId);
            const totResponse = await DaCampTypeTotalApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setTotal(totResponse);
            setLoading(false);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate]);

    if (loading) {
        return (
            <div className={styles.daCampType}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const headers = ["캠페인 유형", "광고비", "노출수", "클릭수", "클릭률", "전환수", "전환 매출액", "광고수익률"]

    const transformedData = data.data.map(item => ({
        // "순위": item.ad_date_dayweek,
        "캠페인 유형": item.campaign_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환수": item.tot_conversion_count,
        "전환 매출액": item.tot_conversion_amount,
        "광고수익률": item.roas,
    }));

    const transformedTotalData = () => {
        return {
            // "일자": "총합",
            "캠페인 유형": "총합",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": total.data.click_rate,
            "전환수": total.data.tot_conversion_count,
            "전환 매출액": total.data.tot_conversion_amount,
            "광고수익률": total.data.roas,
        };
    };

    const totalData = transformedTotalData(); // 총합 데이터 가져오기
    const combinedData = [totalData, ...transformedData];

    const handleSelect = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        setSelectedValue(selectedOption.value);
        setLabel(selectedOption.getAttribute("data-label"));
    };

    return (
        <div className={styles.daCampType}>
            <p className={styles.daCampTypeTitle}>캠페인 유형별 성과 분석</p>
            <div className={styles[`daCampTypeSelect${mediaInfoId}`]}>
                <select value={selectedValue} onChange={handleSelect}>
                    <option data-label="전환 매출액" value="tot_conversion_amount">
                        전환 매출액
                    </option>
                    <option data-label="광고비" value="tot_cost">
                        광고비
                    </option>
                    <option data-label="노출수" value="tot_impression">
                        노출수
                    </option>
                    <option data-label="클릭수" value="tot_click">
                        클릭수
                    </option>
                    <option data-label="전환수" value="tot_conversion_count">
                        전환수
                    </option>
                    <option data-label="광고수익률" value="roas">
                        광고수익률
                    </option>
                </select>
            </div>
            <DaSingleBar data={data} mediaInfoId={mediaInfoId} label={label} value={selectedValue} />
            <ReportDaTable 
                headers={headers} 
                items={combinedData} 
                mediaInfoId={mediaInfoId}
            />
        </div>
    );
}