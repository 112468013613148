import React from "react";

import styles from "../scss/predadvresult.module.scss";
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-adv-result-labels.svg";

import { PredSingleBarLine } from "../../../utils/chart/PredChart";
import { InfoIcon } from "../../../utils/info/InfoIcon";


export const PredAdvResult = ({ startDate, endDate }) => {
    const testData = {
        "data": [
            {
                "date": "2024-10-14",
                "roas": 0.43,
                "tot_conversion_amount": 94305
            },
            {
                "date": "2024-10-15",
                "roas": 0.57,
                "tot_conversion_amount": 116297
            },
            {
                "date": "2024-10-16",
                "roas": 0.21,
                "tot_conversion_amount": 64160
            },
            {
                "date": "2024-10-17",
                "roas": 0.85,
                "tot_conversion_amount": 48074
            },
            {
                "date": "2024-10-18",
                "roas": 0.62,
                "tot_conversion_amount": 8946
            },
        ]
    }

    const message = () => {
        return (
            <>
            광고비 조정에 따라 예측된 광고 목표 성과를 보여줍니다.
            </>
        )
    }

    return (
        <div className={styles.predAdvResult}>
            <div className={styles.predAdvTxt}>
                <p>예상 광고 목표</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.predAdvLabels}>
                <Labels />
            </div>
            <div className={styles.predAdvChart}>
                <PredSingleBarLine data={testData} width="570px" height="320px" />
                <div className={styles.barLineBorder}></div>
            </div>
        </div>
    )
}


