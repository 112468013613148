import React from 'react';
import { useSelector } from 'react-redux';

import styles from './reportkeyword.module.scss';

import { KeywordFunnel } from './funnel/KeywordFunnel';
import { ConversionTrend } from './conversion/ConversionTrend';
import { DateConversion } from './conversion/DateConversion';
import { DeviceConversion } from './conversion/DeviceConversion';
import { DayPerformanceData } from './performance/DayPerformanceData';
import { KeywordTrend } from './trend/KeywordTrend';
import { TopKeyword } from './trend/TopKeyword';
import { KeywordPerformance } from './performance/KeywordPerformance';

import { selectStartDate, selectEndDate } from '../../../../store/dateSlice';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportKeyword = ({mediaInfoId, adAccountsId, setResultData}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));

    return (
        <div className={styles.reportKeyword}>
            <KeywordFunnel mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} setResultData={setResultData} />
            <ConversionTrend mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <div className={styles.conversion}>
                <DateConversion mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <DeviceConversion mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            </div>
            <DayPerformanceData mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <div className={styles.keywordPerformance}>
                <KeywordTrend mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <TopKeyword mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            </div>
            <KeywordPerformance mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
        </div>
    );
};

