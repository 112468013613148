import React from 'react'

import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement,
} from "chart.js";
 
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
    PointElement,
    LineElement
);

export const BarLine = ({ data, lineLabel, barLabel, mediaInfoId, reportType, value }) => {
    const barColor = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 1)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 1)";
        } else if (mediaInfoId === 100 && reportType === "da") {
            return "rgba(46, 103, 156, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 1)";
        } else if (mediaInfoId === 6) {
            return "rgba(0, 176, 80, 1)";
        } else if (mediaInfoId === 7) {
            return "rgba(255, 180, 18, 1)";
        } 
        return ;
    };

    const lineColor = () => {
        if (mediaInfoId === 5) {
            return "rgba(255,139,67,1)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 139, 67, 1)";
        } else if (mediaInfoId === 100) {
            return "rgba(255, 139, 67, 1)";
        } else if (mediaInfoId === 6) {
            return "rgba(30, 0, 203, 1)";
        } else if (mediaInfoId === 7) {
            return "rgba(30, 0, 203, 1)";
        }
        return ;
    }

    const labelHeight = (type) => {
        if (type === "line") {
            return 0;
        } else if (type === "bar") {
            return 10;
        }
        return ;
    }

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 10,
                        weight: "bold"
                    },
                    color: '#000'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "bold"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
            y2: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    font: {
                        family: "Pretendard",
                        size: 11,
                        weight: "bold"
                    },
                    color: '#000'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            },
            layout: {
                padding: {
                    
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: "top",
                    align: "start",
                    display: false,
                    margin: {
                        bottom: 10,
                    },
                    labels: {
                        // usePointStyle: true,
                        
                        boxWidth: 40,
                        boxHeight: 0,
                        color: '#000',
                        // borderWidth: 12,
                        font: {
                            family: "Pretendard",
                            size: 12,
                            weight: "bold",
                        },
                        generateLabels: (chart) => {
                            const datasets = chartData.datasets;
                            return datasets.map((dataset, i) => ({
                                text: dataset.label,
                                fillStyle: dataset.backgroundColor(),
                                strokeStyle: dataset.borderColor(),
                                lineWidth: dataset.borderWidth,
                                hidden: !chart.isDatasetVisible(i),
                                index: i,
                                // line 차트와 bar 차트에 대해 다른 boxWidth 설정
                                boxWidth: dataset.type === "line" ? 40 : 20,
                                boxHeight: dataset.boxHeight(dataset.type)
                            }));
                        },
                    },
                    
                },
                datalabels: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
        },
    };

    const chartData = {
        labels: data.data.map(item => item.date),
        datasets: [
            {
                yAxisID: "y2",
                type: "line",
                label: lineLabel,
                data: data.data.map(item => item.tot_conversion_amount),
                backgroundColor: lineColor,
                borderColor: lineColor,
                borderWidth: 2,
                fill: false,
                tension: 0.1,
                pointRadius: 0,
                boxHeight: labelHeight
            },
            {
                yAxisID: "y",
                type: "bar",
                label: barLabel,
                data: data.data.map(item => item.tot_cost || item[value]),
                backgroundColor: barColor,
                borderColor: barColor,
                // borderWidth: 12,
                // boxWidth: 10,
                barPercentage: 1,
                categoryPercentage: 0.6,
                boxHeight: labelHeight
            }
        ]
    };

    return (
        <>
            {/* <div style={{width: "700px", marginBottom: "10px"}}>{labels}</div> */}
            <Bar data={chartData} options={options} />
        </>
    );
};