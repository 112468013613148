import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './reportcampaignda.module.scss';

import { DaCampaignSumChart } from './summation/DaCampaignSumChart';
import { DaGroupSumChart } from './summation/DaGroupSumChart';
import { DaCampTypeAnalysis } from './analysis/DaCampTypeAnalysis';
import { DaDetailAnalysis } from './analysis/DaDetailAnalysis';

import { selectStartDate, selectEndDate } from '../../../../store/dateSlice';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportCampaignDa = ({mediaInfoId, adAccountsId, reportType}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));
    const [selectedValue, setSelectedValue] = useState("day");

    return (
        <div className={styles.reportCampaignDa}>
            <DaCampaignSumChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} selectedValue={selectedValue} setSelectedValue={setSelectedValue} reportType={reportType} />
            <DaGroupSumChart mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} selectedValue={selectedValue} setSelectedValue={setSelectedValue} reportType={reportType} />
            {mediaInfoId === 7 && 
                <DaCampTypeAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            }
            <DaDetailAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} reportType={reportType} />
            {mediaInfoId === 7 && 
                <></>
            }
        </div>
    );
};