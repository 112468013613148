import React, { useState, useEffect, useContext } from "react";

import styles from "../scss/dacreativetop.module.scss"
import { ReactComponent as UpSort } from "../../../../../assets/images/report/report-da-creative-top-upsort.svg"
import { ReactComponent as DownSort } from "../../../../../assets/images/report/report-da-creative-top-down.svg"

import { SummaryContext } from "../../../summary/DaSummaryProvider";

import { DaCreativeTopApi } from "../../../../../api/reportDA/creative/DaCreativeTopApi";

import Loading from "../../../../utils/loading/Loading";

export const DaCreativeTop = ({
    mediaInfoId,
    startDate,
    endDate,
    adAccountsId
}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState("tot_conversion_amount DESC");
    const { setSubject, setHighSub } = useContext(SummaryContext);
 
    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    }

    const headers = ["광고 소재명", "광고 그룹명", "노출수", "클릭수", "클릭률", "전환 매출액", "이전 기간 매출 비교"]

    const handleResponse = (response) => {
        // console.log(response.data)
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCreativeTopApi(mediaName(), startDate, endDate, adAccountsId, orderBy);
            setData(response);
            setLoading(false);
            handleResponse(response);
            // console.log(response);
        };
        fetchData();
    }, [startDate, endDate, orderBy])

    if (loading) {
        return (
            <div className={styles.daCreativeTop}>
                {/* <Loading /> */}
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "광고 소재명": item.creative_name,
        "광고 그룹명": item.ad_group_name,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환 매출액": item.tot_conversion_amount,
        "이전 기간 매출 비교": item.roas,
        creative_url_name: item.creative_url_name
    }))

    console.log(transformedData);

    return (
        <div className={styles.daCreativeTop}>
            <p className={styles.daCreativeTopTitle}>광고 소재 성과 TOP3</p>
            <div className={styles[`daCreativeTopSelect${mediaInfoId}`]}>
                <select onChange={e => setOrderBy(e.target.value)}>
                    <option key="전환 매출액" value="tot_conversion_amount DESC">
                        전환 매출액
                    </option>
                    <option key="클릭수" value="tot_click DESC">
                        클릭수
                    </option>
                    <option key="클릭률" value="click_rate DESC">
                        클릭률
                    </option>
                </select>
            </div>
            <div className={styles.daCreativeBox}>
                {transformedData.map((item, index) => {
                    return (
                        <div key={index} className={styles.daCreativeContainer}>
                            <div className={styles.imgBox}>
                                <img src={item.creative_url_name} alt={`Creative ${index}`} style={{width: "273px", height: "165px"}}/>
                            </div>
                            <div className={styles.daCreativeTable}>
                            {headers.map((header, headerIndex) => { 
                                return (
                                    <div className={styles.tableRow}>
                                        <div className={styles.tableHeader}>
                                            <p>{header}</p>
                                        </div>
                                        <div className={styles.tableBody}>
                                        <p>
                                            {{
                                                "노출수": item[header].toLocaleString(),
                                                "클릭수": item[header].toLocaleString(),
                                                "전환 매출액": item[header].toLocaleString(),
                                                "클릭률": `${item[header]}%`,
                                            }[header] || item[header]}
                                            {header === "이전 기간 매출 비교" && (
                                                <>
                                                    % <UpSort />
                                                </>
                                            )}
                                        </p>
                                        </div>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}