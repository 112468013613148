import axios from "../axios";

export const ChangeReportNameApi = async (incrementId, newName) => {
    try {
        const { data }  = await axios.patch("/report/create/", { 
            incrementId: incrementId,
            report_nm: newName
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const ChangePredictNameApi = async (incrementId, newName) => {
    const path = `/prediction_model/reports/${incrementId}/`
    try {
        const payload = {
            name: newName
        }
        const { data }  = await axios.patch(path, payload, { 
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
};