import React, { useState, useEffect } from 'react';

import styles from '../scss/dayperformancedata.module.scss';
import {ReactComponent as ExcelDownloadNaver} from '../../../../../assets/images/report/report-excel-down-naver.svg';
import {ReactComponent as ExcelDownloadKakao} from '../../../../../assets/images/report/report-excel-down-kakao.svg';
import {ReactComponent as ExcelDownloadGoogle} from '../../../../../assets/images/report/report-excel-down-google.svg';

import { KeywordDayPerformanceDataApi, KeywordDayPerformanceTotalApi, KeywordDayPerformanceDownloadApi } from '../../../../../api/report/keyword/KeywordDayPerformanceApi';

import { ReportTable } from '../../../../utils/table/ReportTable';
import Loading from '../../../../utils/loading/Loading';
import { InfoIcon } from '../../../../utils/info/InfoIcon';

export const DayPerformanceData = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const pageSize = 10;
    const [row, setRow] = useState(null);
    const [orderBy, setOrderBy] = useState("ad_date ASC");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedButtons, setSelectedButtons] = useState({
        '클릭률': false,
        '클릭당 비용': false,
        '전환 매출액': true,
        '전환율': false,
        '전환수': true,
        '광고수익률': true
    });
    const [prevDateRange, setPrevDateRange] = useState({ startDate: null, endDate: null });

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    useEffect(() => {
        const fetchData = async () => {
            const [dayPerformanceResponse] = await Promise.all([
                KeywordDayPerformanceDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy)
            ]);
            setData(dayPerformanceResponse);
            
            if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
                const totalPerformanceResponse = await KeywordDayPerformanceTotalApi(mediaName(), startDate, endDate, adAccountsId);
                setTotal(totalPerformanceResponse);
                setPrevDateRange({ startDate, endDate });
                // console.log(totalPerformanceResponse)
            }
            
            setLoading(false);
        };
        fetchData();
    }, [startDate, endDate, row, orderBy]);

    if (loading) {
        return (
            <div className={styles.dayPerformanceData}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const transformedData = data.data.map(item => ({
        "일자": item.ad_date_dayweek,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "클릭당 비용": item.cost_click,
        "전환 매출액": parseInt(item.tot_conversion_amount,10),
        "전환율": item.conversion_rate,
        "전환수": parseInt(item.tot_conversion_count,10),
        "광고수익률": item.roas,
    }))

    const transformedTotalData = () => {
        return {
            "일자": "총합",
            "광고비": total.data.tot_cost,
            "노출수": total.data.tot_impression,
            "클릭수": total.data.tot_click,
            "클릭률": parseFloat(total.data.click_rate),
            "클릭당 비용": total.data.cost_click,
            "전환 매출액": parseInt(total.data.tot_conversion_amount,10),
            "전환율": parseFloat(total.data.conversion_rate),
            "전환수": parseInt(total.data.tot_conversion_count,10),
            "광고수익률": total.data.roas,
        };
    };

    const toggleButton = (buttonName) => {
        setSelectedButtons(prev => ({
            ...prev,
            [buttonName]: !prev[buttonName]
        }));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
    };

    const getExcelDownloadIcon = () => {
        switch (mediaInfoId) {
            case 5:
                return <ExcelDownloadNaver />;
            case 89:
                return <ExcelDownloadKakao />;
            case 100:
                return <ExcelDownloadGoogle />;
            default:
                return ; // 기본값
        }
    };
    
    const handleDownload = async () => {
        let fields = [];
    
        // selectedButtons가 true인 버튼에 따라 fields 설정
        if (Object.values(selectedButtons).every(value => value === true)) {
            fields.push('all'); // 모든 버튼이 true일 경우
        } else {
            // selectedButtons에서 true인 항목만 fields에 추가
            for (const [key, value] of Object.entries(selectedButtons)) {
                if (value) {
                    switch (key) {
                        case '클릭률':
                            fields.push('click_rate');
                            break;
                        case '클릭당 비용':
                            fields.push('cost_click');
                            break;
                        case '전환 매출액':
                            fields.push('tot_conversion_amount');
                            break;
                        case '전환율':
                            fields.push('conversion_rate');
                            break;
                        case '전환수':
                            fields.push('tot_conversion_count');
                            break;
                        case '광고수익률':
                            fields.push('roas');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    
        // 고정으로 전달할 필드 추가
        fields.push('tot_cost', 'tot_impression', 'tot_click');
    
        try {
            const response = await KeywordDayPerformanceDownloadApi(
                mediaName(),
                startDate,
                endDate,
                adAccountsId,
                fields.join(','),
                orderBy,
                'blob'
            );

            if (!response.headers) {
                throw new Error("응답에 헤더가 포함되어 있지 않습니다.");
            }

            const disposition = response.headers['content-disposition'];
            let fileName = 'download.xlsx';

            if (disposition && disposition.includes('filename=')) {
                const fileNameMatch = disposition.match(/filename[^;=\n]*=(['"]?)([^'"]*)\1/);
                if (fileNameMatch != null && fileNameMatch.length > 2) {
                    fileName = `${decodeURIComponent(fileNameMatch[2])}.xlsx`;
                }
            }

            
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("다운로드 중 오류 발생:", error);
        }
    };

    const message = () => {
        return (
            <>
            광고수익률 순으로 주요 키워드를 시각적으로 나타냅니다.<br/>
            키워드의 크기가 클수록 해당 키워드가 더 높은 수익을 기록한 것을 의미합니다.
            </>
        )
    }

    return (
        <div className={styles.dayPerformanceData}>
            <div className={styles.dayPerformanceDataTitle}>
                <p>일자별 광고성과 데이터</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.dataSelectBox}>
                <div className={styles[`dataSelectButton${mediaInfoId}`]}>
                    {Object.keys(selectedButtons).map((buttonName) => (
                        <button
                            key={buttonName}
                            className={selectedButtons[buttonName] ? styles.active : ''}
                            onClick={() => toggleButton(buttonName)}
                        >
                            {buttonName}
                        </button>
                    ))}
                </div>
                <button className={styles.excelDownload} onClick={handleDownload}>
                    {getExcelDownloadIcon()}
                </button>
            </div>
            <ReportTable 
                defaultHeaders={['일자','광고비','노출수','클릭수']} 
                headers={selectedButtons} 
                items={transformedData} 
                totalItems={transformedTotalData()} 
                pageData={data.page}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                mediaInfoId={mediaInfoId}
                setOrderBy={setOrderBy}
                defaultOrderBy={"ad_date ASC"}
            />
        </div>
    )
}
