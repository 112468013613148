import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { SummaryContext } from "./SummaryProvider";

import styles from "./summary.module.scss";

import { selectStartDate, selectEndDate } from "../../../store/dateSlice";

export const Summary = ({mediaInfoId, selectedButton}) => {
    const { 
        cost, 
        click, 
        amount, 
        conversion, 
        conversionRate, 
        conversionCost, 
        clickCostVar, 
        clickRateVar,
        topKeyword, 
        highDay, 
        lowDay, 
        deviceCampaignKeyword, 
        deviceCampaignClick,
        deviceCampaignCost,
        topCampaign, 
        clickRateComp, 
        lowKeyword, 
        highKeyword 
    } = useContext(SummaryContext);

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    // console.log(
    //     deviceCampaignCost
    // );

    if (cost === null || click === null || amount === null || conversion === null || topCampaign === null || deviceCampaignKeyword === null || deviceCampaignCost === null) {
        return <div className={styles.summary}>로딩 중...</div>;
    }

    const mentData = () => {
        if (selectedButton === 'keyword') {
            return [
                // {ment : `설정된 기간동안,<span style="color: red">총 ${cost.toLocaleString()}원</span>의 광고비용이 소진되었습니다.`},
                // {ment : `방문자 <span style="color: red">${click.toLocaleString()}명</span>중 ${conversion.toLocaleString()}명이 전환을 발생시켰고, <span style="color: red">1명 당 평균적으로 ${(amount/conversion).toLocaleString()}원</span>의 매출을 발생시켰어요!`},
                // {ment : `100명의 방문자 중 약 <span style="color: red">${conversionRate}명</span> 만 전환으로 이어지고 있어요! 현재 1명의 전환을 발생시키는데 약 <span style="color: red">${conversionCost.toLocaleString()}원</span>의 광고비가 필요한 상황이에요!`},
                {ment : `전환매출액이 가장 높은 요일은 <span style="color: red">${highDay.amount}요일</span>이고 가장 낮은 요일은 <span style="color: red">${lowDay.amount}요일</span>이에요! <span style="color: red">${lowDay.amount}요일</span>의 광고비중을 줄이고 <span style="color: red">${highDay.amount}요일</span> 비중을 늘려보시는건 어떨까요?`},
                {ment : `<span style="color: red">${deviceCampaignKeyword.roas[0]}캠페인이 성과가 가장 높은 것으로 확인되었어요!</br> ${deviceCampaignKeyword.roas[0]}캠페인</span> 중심의 마케팅 전략을 강화하거나, 캠페인을 더욱 최적화하는 것이 효과적일 수 있습니다.`},
                {ment : `전환수가 가장 높은 키워드는 <span style="color: red">'${topKeyword.convCount}'</span>입니다.</br> 전환 매출액이 가장 높은 키워드는 <span style="color: red">'${topKeyword.convAmount}'</span>입니다.</br> 광고 수익률이 가장 높은 키워드는 <span style="color: red">'${topKeyword.roas}'</span>입니다.</br>위 키워드들에 대한 집중적인 투자와 최적화가 진행된다면 더 큰 성과를 예상해볼 수 있습니다.`},
                {ment : `<span style="color: red">'${lowKeyword.avgRank}'</span>의 경우 평균 노출 순위가 낮습니다.</br> 이 키워드에 대한 광고 전략을 상위 순위 키워드의 광고 전략을 참고하여 재검토하는 것이 필요합니다.`}
            ];
        } else if (selectedButton === 'impression') {
            return [
                {ment : `클릭률이 가장 높은 요일은 <span style="color: red">${highDay.clickRate}요일</span>이고 가장 낮은 요일은 <span style="color: red">${lowDay.clickRate}요일</span>이에요! <span style="color: red">${lowDay.clickRate}요일</span>의 광고비중을 줄이고 <span style="color: red">${highDay.clickRate}요일</span> 비중을 늘려보시는건 어떨까요?`},
                {ment : `<span style="color: red">${deviceCampaignClick.clickRate[0]}캠페인이 성과가 가장 높은 것으로 확인되었어요!</br> ${deviceCampaignClick.clickRate[0]}캠페인</span> 중심의 마케팅 전략을 강화하거나, 캠페인을 더욱 최적화하는 것이 효과적일 수 있습니다.`},
                {ment : `<span style="color: red">${topCampaign.clickRate} 캠페인</span>이 클릭률에서 탁월한 성과를 보여주고 있어요</br>위 캠페인에 대해서 집중적인 투자와 최적화를 진행한다면, 더 큰 성과를 기대할 수 있을거에요!`},
                {ment : `<span style="color: red">${clickRateComp.highKeyword}</span>에서 클릭률이 가장 높게 나타나고 있습니다.</br>이 키워드를 중심으로 한 캠페인 확장이 매출 증가로 이어질 수 있습니다!</br><span style="color: red">${clickRateComp.lowKeyword}</span>에서 클릭률이 가장 낮습니다. 키워드를 재조정하여 성과를 개선시킬 수 있는 전략이 필요합니다.`}
            ];
        } else if (selectedButton === 'cost') {
            return [
                {ment : `클릭당 비용이 가장 높은 요일은 <span style="color: red">${highDay.cost}요일</span>이고 가장 낮은 요일은 <span style="color: red">${lowDay.cost}요일</span>이에요! <span style="color: red">${lowDay.cost}요일</span>의 광고비중을 줄이고 <span style="color: red">${highDay.cost}요일</span> 비중을 늘려보시는건 어떨까요?`},
                // {ment : `<span style="color: red">${deviceCampaignCost.cost[0]}캠페인이 성과가 가장 높은 것으로 확인되었어요!</br> ${deviceCampaignCost.cost[0]}캠페인</span> 중심의 마케팅 전략을 강화하거나, 캠페인을 더욱 최적화하는 것이 효과적일 수 있습니다.`},
                {ment : `<span style="color: red">${topCampaign.roas} 캠페인</span>이 클릭당 비용이 가장 낮게 나타나고 있어요</br>위 캠페인에 대해서 집중적인 투자와 최적화를 진행한다면, 더 큰 성과를 기대할 수 있을거에요!`},
            ];
        }
        return []; 
    }

    return (
        <div className={styles.summary}>
            <div className={styles.summaryTitle}>
                <p className={styles.summaryTitleText}>보고서 핵심 요약</p>
                <p className={styles.summaryTitleDate}>({startDate} ~ {endDate})</p>
            </div>
            <div className={styles[`summaryContent${mediaInfoId}`]}>
                {mentData().map((data, index) => (
                    <p key={index} className={styles.summaryContentText} dangerouslySetInnerHTML={{ __html: data.ment }} />
                ))}
            </div>
        </div>
    );
};