import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/deviceconversion.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { KeywordDeviceConversionApi } from '../../../../../api/report/keyword/KeywordDeviceConversionApi';

import { DevicePieChart } from '../../../../utils/chart/DevicePieChart';
import Loading from '../../../../utils/loading/Loading';

export const DeviceConversion = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("count");
    const { setDeviceCampaignKeyword } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const compareRoas = (data) => {
        let moData = "";
        let pcData = "";
        if (mediaInfoId === 100) {
            moData = data.find(item => item.device_type === "MOBILE");
            pcData = data.find(item => item.device_type === "DESKTOP");
        } else {
            moData = data.find(item => item.device_type === "MO");
            pcData = data.find(item => item.device_type === "PC");
        }

        if (moData && pcData) {
            const moRoas = moData.roas;
            const pcRoas = pcData.roas;
            const moConvAmount = moData.tot_conversion_amount;
            const pcConvAmount = pcData.tot_conversion_amount;
            
            const diffRoas = (moRoas - pcRoas); // 비율 계산
            const diffConvAmount = ((moConvAmount - pcConvAmount) / (pcConvAmount)) * 100;

            if (diffRoas > 0) {
                setDeviceCampaignKeyword( {roas: ["MO", diffRoas.toFixed(2)], convAmount: ["MO", diffConvAmount.toFixed(2)]});
            } else if (diffRoas < 0) {
                setDeviceCampaignKeyword( {roas: ["PC", Math.abs(diffRoas.toFixed(2))], convAmount: ["MO", Math.abs(diffConvAmount.toFixed(2))]});
            }
        }
        return null; // 조건에 맞지 않으면 null 반환
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordDeviceConversionApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response)
            compareRoas(response.data);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <div className={styles.deviceConversion}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const color1 = () => {
        if (mediaInfoId === 5) {
            return 'rgba(0, 176, 80, 1)';
        } else if (mediaInfoId === 89) {
            return 'rgba(255, 180, 18, 1)';
        } else if (mediaInfoId === 100) {
            return 'rgba(0, 114, 240, 1)';
        }
        return ;
    };

    const color2 = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 0.2)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 0.2)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 0.2)";
        }
        return ;
    };

    const color3 = () => {
        if (mediaInfoId === 100) {
            return "#76B7FF"
        }
        return ;
    }

    const chartData = data.data.map(item => ({
        device: item.device_type,
        value: selectedValue === "amount" ? item.tot_conversion_amount :
               selectedValue === "roas" ? item.roas :
               item.tot_conversion_count
    }));

    return (
        <div className={styles.deviceConversion}>
            <div className={styles.deviceConversionTitle}>
                <p>PC/MO 별 전환</p>
                <div className={styles[`deviceConversionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="count">전환수</option>
                        <option value="amount">전환 매출액</option>
                        <option value="roas">광고수익률</option>
                    </select>
                </div>
            </div>
            <div className={styles.deviceConversionContent}>
                <DevicePieChart data={chartData} color1={color1()} color2={color2()} color3={color3()} selectedValue={selectedValue} />
            </div>
        </div>
    )
}