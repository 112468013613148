import React, { useState, useEffect, useContext } from 'react';

import styles from "../scss/detailedanalysis.module.scss";

import { SummaryContext } from '../../../summary/SummaryProvider';

import { 
    ClickCampaignDataApi,
    ClickCampaignTotalApi,
    ClickGroupDataApi,
    ClickGroupTotalApi,
    ClickKeywordDataApi,
    ClickKeywordTotalApi
} from '../../../../../api/report/click/ClickDetailAnalysisApi';

import { CampaignAnalysis } from './CampaignAnalysis';
import { GroupAnalysis } from './GroupAnalysis';
import { KeywordAnalysis } from './KeywordAnalysis';

export const DetailedAnalysis = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [campaginData, setCampaignData] = useState(null);
    const [campaginTotal, setCampaignTotal] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [groupTotal, setGroupTotal] = useState(null);
    const [keywordData, setKeywordData] = useState(null);
    const [keywordTotal, setKeywordTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [adLevel, setadLevel] = useState('캠페인');
    const [prevDateRange, setPrevDateRange] = useState({ startDAte: null, endDate: null });
    const [buttonClicked, setButtonClicked] = useState(null);
    const [row, setRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [pageChange, setPageChange] = useState(null);
    const { setClickRateComp } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const handleResponse = (clickRateHighKeyword, clickRateLowKeyword) => {
        const lowKeywords = clickRateLowKeyword.data.slice(0, 1).map(item => item.campaign_name);
        const highKeywords = clickRateHighKeyword.data.slice(0, 1).map(item => item.campaign_name);
        // console.log(lowKeywords, highKeywords)
        setClickRateComp({highKeyword: highKeywords, lowKeyword: lowKeywords})
    }

    useEffect(() => {
        setRow(0);
        setCurrentPage(1);
    }, [adLevel]);

    const campaignFetchData = async (orderBy) => {
        const [detailAnalysisResponse] = await Promise.all([
            ClickCampaignDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy)
        ]);
        setCampaignData(detailAnalysisResponse);
        
        if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
            const totalDetailAnalysisResponse = await ClickCampaignTotalApi(mediaName(), startDate, endDate, adAccountsId);
            setCampaignTotal(totalDetailAnalysisResponse);
            setGroupTotal(totalDetailAnalysisResponse);
            setKeywordTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        };
        
        setLoading(false);

        const clickRateHighKeyword = await ClickCampaignDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, 'click_rate DESC');
        const clickRateLowKeyword = await ClickCampaignDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, 'click_rate ASC');
        handleResponse(clickRateHighKeyword,clickRateLowKeyword);
    };

    const groupFetchData = async (orderBy, campaignId) => {
        const [detailAnalysisResponse] = await Promise.all([
            ClickGroupDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy, campaignId)
        ]);
        setGroupData(detailAnalysisResponse);
        
        if (campaignId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await ClickGroupTotalApi(mediaName(), startDate, endDate, adAccountsId, campaignId);
            setGroupTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        };
        
        setLoading(false);
    };

    const keywordFetchData = async (orderBy, groupId) => {
        // console.log(groupId)
        const [detailAnalysisResponse] = await Promise.all([
            ClickKeywordDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy, groupId)
        ]);
        setKeywordData(detailAnalysisResponse);
        
        if (groupId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await ClickKeywordTotalApi(mediaName(), startDate, endDate, adAccountsId, groupId);
            setKeywordTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        };
        
        setLoading(false);
    };

    const handleButtonClick = (buttonName) => {
        setadLevel(buttonName);
        setButtonClicked("버튼클릭");
    }; 

    const handleCampaignSelect = (pageSize, row, orderBy, campaignId) => {
        groupFetchData(pageSize, row, orderBy, campaignId);
        setadLevel("광고그룹");
        setButtonClicked("캠페인 선택");
    };

    const handleGroupSelect = (pageSize, row, orderBy, groupId) => {
        keywordFetchData(pageSize, row, orderBy, groupId);
        setadLevel("키워드");
        setButtonClicked("그룹 선택");
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
        setPageChange("click");
    };

    return (
        <div className={styles.detailedAnalysis}>
            <div className={styles.detailedAnalysisTitle}>노출/클릭 상세 분석</div>
            <div className={styles.detailedAnalysisButton}>
                <button 
                    className={adLevel === '캠페인' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('캠페인')}
                >
                    캠페인
                </button>
                <button 
                    className={adLevel === '광고그룹' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('광고그룹')}
                >
                    광고그룹
                </button>
                <button 
                    className={adLevel === '키워드' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('키워드')}
                >
                    키워드
                </button>
            </div>
            {adLevel === "캠페인" && 
                <CampaignAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={campaignFetchData}
                    loading={loading}
                    data={campaginData}
                    total={campaginTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleCampaignSelect={handleCampaignSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                />
            }
            {adLevel === "광고그룹" && 
                <GroupAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={groupFetchData}
                    loading={loading}
                    data={groupData}
                    total={groupTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleGroupSelect={handleGroupSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                />
            }
            {adLevel === "키워드" && 
                <KeywordAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    fetchData={keywordFetchData}
                    loading={loading}
                    data={keywordData}
                    total={keywordTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                />
            }
        </div>
    );
};
