import React, { useState, useEffect } from 'react';

import styles from "../scss/dadetailanalysis.module.scss";

import { 
    DaDetailCampaignDataApi,
    DaDetailCampaignTotalApi,
    DaDetailGroupDataApi,
    DaDetailGroupTotalApi,
    DaDetailCreativeDataApi,
    DaDetailCreativeTotalApi,
} from '../../../../../api/reportDA/campaign/DaDetailAnalysisApi';

import { DaCampaignAnalysis } from './DaCampaignAnaysis';
import { DaGroupAnalysis } from './DaGroupAnalysis';
import { DaCreativeAnalysis } from './DaCreativeAnalysis';

export const DaDetailAnalysis = ({mediaInfoId, adAccountsId, startDate, endDate, reportType}) => {
    const [campaginData, setCampaignData] = useState(null);
    const [campaginTotal, setCampaignTotal] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const [groupTotal, setGroupTotal] = useState(null);
    const [creativeData, setCreativeData] = useState(null);
    const [creativeTotal, setCreativeTotal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [adLevel, setadLevel] = useState('캠페인');
    const [prevDateRange, setPrevDateRange] = useState({ startDAte: null, endDate: null });
    const [buttonClicked, setButtonClicked] = useState(null);
    const [row, setRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = adLevel === '소재' ? 5 : 10;
    const [pageChange, setPageChange] = useState(null);

    const mediaName = () => {
        if (mediaInfoId === 6) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    useEffect(() => {
        setRow(0);
        setCurrentPage(1);
    }, [adLevel]);

    const campaignFetchData = async (orderBy) => {
        const [detailAnalysisResponse] = await Promise.all([
            DaDetailCampaignDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy)
        ]);
        setCampaignData(detailAnalysisResponse);

        // const convCostDESC = CostcreativeDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy);
        // const convCostASC = CostcreativeDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy);

        
        if (startDate !== prevDateRange.startDate || endDate !== prevDateRange.endDate) {
            const totalDetailAnalysisResponse = await DaDetailCampaignTotalApi(mediaName(), startDate, endDate, adAccountsId);
            setCampaignTotal(totalDetailAnalysisResponse);
            setGroupTotal(totalDetailAnalysisResponse);
            setCreativeTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        }
        
        setLoading(false);
    };

    const groupFetchData = async (orderBy, campaignId) => {
        const [detailAnalysisResponse] = await Promise.all([
            DaDetailGroupDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy, campaignId)
        ]);
        setGroupData(detailAnalysisResponse);
        
        if (campaignId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await DaDetailGroupTotalApi(mediaName(), startDate, endDate, adAccountsId, campaignId);
            setGroupTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        }
        
        setLoading(false);
    };

    const creativeFetchData = async (orderBy, groupId) => {
        const [detailAnalysisResponse] = await Promise.all([
            DaDetailCreativeDataApi(mediaName(), startDate, endDate, adAccountsId, pageSize, row, orderBy, groupId)
        ]);
        setCreativeData(detailAnalysisResponse);
        
        if (groupId || buttonClicked === "버튼클릭") {
            const totalDetailAnalysisResponse = await DaDetailCreativeTotalApi(mediaName(), startDate, endDate, adAccountsId, groupId);
            setCreativeTotal(totalDetailAnalysisResponse);
            setPrevDateRange({ startDate, endDate });
        }
        
        setLoading(false);
    };

    const handleButtonClick = (buttonName) => {
        setadLevel(buttonName);
        setButtonClicked("버튼클릭");
    }; 

    const handleCampaignSelect = (pageSize, row, orderBy, campaignId) => {
        groupFetchData(pageSize, row, orderBy, campaignId);
        setadLevel("광고그룹");
        setButtonClicked("캠페인 선택");
    };

    const handleGroupSelect = (pageSize, row, orderBy, groupId) => {
        creativeFetchData(pageSize, row, orderBy, groupId);
        setadLevel("소재");
        setButtonClicked("그룹 선택");
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRow((page - 1) * pageSize);
        setPageChange("click");
    };

    return (
        <div className={adLevel === '소재' ? styles.creativeAnalysis : styles.detailedAnalysis}>
            <div className={styles.detailedAnalysisTitle}>성과 상세 분석</div>
            <div className={styles.detailedAnalysisButton}>
                <button 
                    className={adLevel === '캠페인' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('캠페인')}
                >
                    캠페인
                </button>
                <button 
                    className={adLevel === '광고그룹' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('광고그룹')}
                >
                    광고그룹
                </button>
                <button 
                    className={adLevel === '소재' ? styles[`selected${mediaInfoId}`] : styles[`noSelected${mediaInfoId}`]}
                    onClick={() => handleButtonClick('소재')}
                >
                    소재
                </button>
            </div>
            {adLevel === "캠페인" && 
                <DaCampaignAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={campaignFetchData}
                    loading={loading}
                    data={campaginData}
                    total={campaginTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleCampaignSelect={handleCampaignSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    reportType={reportType}
                />
            }
            {adLevel === "광고그룹" && 
                <DaGroupAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    setadLevel={setadLevel}
                    fetchData={groupFetchData}
                    loading={loading}
                    data={groupData}
                    total={groupTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handleGroupSelect={handleGroupSelect}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    reportType={reportType}
                />
            }
            {adLevel === "소재" && 
                <DaCreativeAnalysis
                    mediaInfoId={mediaInfoId} 
                    adAccountsId={adAccountsId} 
                    startDate={startDate} 
                    endDate={endDate} 
                    adLevel={adLevel}
                    fetchData={creativeFetchData}
                    loading={loading}
                    data={creativeData}
                    total={creativeTotal}
                    buttonClicked={buttonClicked}
                    setButtonClicked={setButtonClicked}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    pageChange={pageChange}
                    setPageChange={setPageChange}
                    reportType={reportType}
                />
            }
        </div>
    );
};
