import React from "react";

import styles from "../scss/advcostprev.module.scss"
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-prev-labels.svg"

import DaPieChart from "../../../utils/chart/DaPieChart";
import { PredBarLine } from "../../../utils/chart/PredChart";
import { PrevWeekTable } from "../../period/weekly/prev/PrevWeekTable";



export const AdvCostPrev = () => {

    const testData = {
        data: [
            {media: "네이버", landing_type: "파워 링크", tot_cost: 60000, tot_impression: 2131, tot_click: 2144, click_rate: "0.48%", tot_conversion_count: 432, tot_conversion_amount: 5433, roas: "0.24%"},
            {media: "카카오", landing_type: "카카오 키워드", tot_cost: 50000, tot_impression: 2131, tot_click: 2144, click_rate: "0.48%", tot_conversion_count: 432, tot_conversion_amount: 5433, roas: "0.24%"},
            {media: "구글", landing_type: "검색 광고", tot_cost: 40000, tot_impression: 2131, tot_click: 2144, click_rate: "0.48%", tot_conversion_count: 432, tot_conversion_amount: 5433, roas: "0.24%"},
            {media: "메타", landing_type: "디스플레이 광고", tot_cost: 50000, tot_impression: 2131, tot_click: 2144, click_rate: "0.48%", tot_conversion_count: 432, tot_conversion_amount: 5433, roas: "0.24%"},
        ],
        total:{
            sum_tot_cost: 200000
        }
    }

    return (
        <div className={styles.advCostPrev}>
            <p className={styles.advCostPrevTitle}>이전 일주일 성과</p>
            <div className={styles.advCostPrevChart}>
                <div className={styles.advCostPrevPie}>
                    <p className={styles.chartTxt}>지난주 광고비</p>
                    <DaPieChart data={testData} mediaInfoId={999} />
                </div>
                <div className={styles.advCostPrevBarLine}>
                    <p className={styles.chartTxt}>광고비 매출 추이</p>
                    <div style={{width: "600px"}}><Labels /></div>
                    <PredBarLine data={testData}/>
                </div>
            </div>
            <PrevWeekTable data={testData}/>
        </div>
    )
}