import React from "react";

import styles from "../scss/resultweekly.module.scss"
import { ReactComponent as Labels } from "../../../../../assets/images/predict/pred-result-line-labels.svg"

import { PredLine } from "../../../../utils/chart/PredChart";

export const ResultLine = () => {
    const testData = {
        "data": [
          {
            "media_name": "네이버",
            "data": [
              {
                "date": "2024-10-13",
                "tot_impression": 194886,
                "tot_click": 741,
                "click_rate": 0.38
              },
              {
                "date": "2024-10-14",
                "tot_impression": 357342,
                "tot_click": 1636,
                "click_rate": 0.46
              },
              {
                "date": "2024-10-15",
                "tot_impression": 359519,
                "tot_click": 1574,
                "click_rate": 0.44
              },
              {
                "date": "2024-10-16",
                "tot_impression": 340884,
                "tot_click": 1407,
                "click_rate": 0.41
              },
              {
                "date": "2024-10-17",
                "tot_impression": 345421,
                "tot_click": 1247,
                "click_rate": 0.36
              }
            ]
          },
          {
            "media_name": "카카오",
            "data": [
              {
                "date": "2024-10-13",
                "tot_impression": 52445,
                "tot_click": 140,
                "click_rate": 0.27
              },
              {
                "date": "2024-10-14",
                "tot_impression": 103253,
                "tot_click": 367,
                "click_rate": 0.36
              },
              {
                "date": "2024-10-15",
                "tot_impression": 102680,
                "tot_click": 323,
                "click_rate": 0.31
              },
              {
                "date": "2024-10-16",
                "tot_impression": 112307,
                "tot_click": 291,
                "click_rate": 0.26
              },
              {
                "date": "2024-10-17",
                "tot_impression": 127576,
                "tot_click": 305,
                "click_rate": 0.24
              }
            ]
          },
          {
            "media_name": "구글",
            "data": [
              {
                "date": "2024-10-13",
                "tot_impression": 32120,
                "tot_click": 38,
                "click_rate": 0.12
              },
              {
                "date": "2024-10-14",
                "tot_impression": 33112,
                "tot_click": 100,
                "click_rate": 0.3
              },
              {
                "date": "2024-10-15",
                "tot_impression": 31991,
                "tot_click": 89,
                "click_rate": 0.28
              },
              {
                "date": "2024-10-16",
                "tot_impression": 31493,
                "tot_click": 88,
                "click_rate": 0.28
              },
              {
                "date": "2024-10-17",
                "tot_impression": 31357,
                "tot_click": 67,
                "click_rate": 0.21
              }
            ]
          },
          {
            "media_name": "메타",  
            "data": [
              {
                "date": "2024-10-13",
                "tot_impression": 284784,
                "tot_click": 512,
                "click_rate": 0.18
              },
              {
                "date": "2024-10-14",
                "tot_impression": 278437,
                "tot_click": 642,
                "click_rate": 0.23
              },
              {
                "date": "2024-10-15",
                "tot_impression": 262457,
                "tot_click": 585,
                "click_rate": 0.22
              },
              {
                "date": "2024-10-16",
                "tot_impression": 252955,
                "tot_click": 564,
                "click_rate": 0.22
              },
              {
                "date": "2024-10-17",
                "tot_impression": 246151,
                "tot_click": 492,
                "click_rate": 0.2
              }
            ]
          }
        ]
      }
    return (
        <div className={styles.resultBarLine}>
            <div className={styles.lineBorder}></div>
            <Labels />
            <PredLine data={testData.data} width="550px" height="270px" pointRadius="0" />
        </div>
    )
}