import React, { useState, useEffect, useContext } from 'react';

import { SummaryContext } from '../../../summary/SummaryProvider';

import styles from "../scss/keywordfunnel.module.scss"

import { KeywordFunnelApi } from '../../../../../api/report/keyword/KeywordFunnelApi';
import Loading from '../../../../utils/loading/Loading';
import { InfoIcon } from '../../../../utils/info/InfoIcon';

const FunnelStep = ({ step, text, value, mediaInfoId }) => (
    <div className={styles[`keywordFunnelChart${step}${mediaInfoId}`]}>
        <p className={styles[`chart${step}${mediaInfoId}Text1`]}>{text}</p>
        <p className={styles[`chart${step}${mediaInfoId}Text2`]}>{value}</p>
    </div>
);

const DataItem = ({ name, value, percent, operator, className }) => {
    const percentValue = operator;
    const percentClass = percentValue === "up" ? 'funnelPositive' : percentValue === "down" ? 'funnelNegative' : 'funnelZeroChange';
    const formattedPercent = percentValue === "up" ? `+${percent}%` : percentValue === "down" ? `-${percent}%` : `-`;

    return (
        <div className={styles[className]}>
            <p className={styles.funnelDataName}>{name}</p>
            <p className={styles.funnelDataValue}>{value}</p>
            <pre className={styles[percentClass]}>{formattedPercent}</pre>
        </div>
    );
};

const formatNumber = (num) => {
    return parseInt(num,10).toLocaleString();
};

export const KeywordFunnel = ({mediaInfoId, adAccountsId, startDate, endDate, setResultData}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setCost, setClick, setAmount, setConversion, setConversionRate, setConversionCost, setClickCostVar, setClickRateVar  } = useContext(SummaryContext);

    

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await KeywordFunnelApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            // console.log(response);
            setResultData({conversion: response.data.tot_conversion_count, conversionAmount:response.data.tot_conversion_amount})
            setCost(response.data.tot_cost);
            setClick(response.data.tot_click);
            setAmount(response.data.tot_conversion_amount);
            setConversion(response.data.tot_conversion_count);
            setConversionRate(response.data.conversion_rate);
            setConversionCost(response.data.conversion_cost);
            setClickCostVar({variance: response.data.cost_click_variance, operator: response.data.cost_click_variance_operator});
            setClickRateVar({variance: response.data.click_rate_variance, operator: response.data.click_rate_variance_operator});
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <div className={styles.keywordFunnel}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const funnelSteps = [
        { step: 'First', text: '1단계', value: `${formatNumber(data.data.tot_impression)} 회 노출`, mediaInfoId },
        { step: 'Second', text: '2단계', value: `${formatNumber(data.data.tot_click)} 회 클릭`, mediaInfoId },
        { step: 'Third', text: '3단계', value: `${formatNumber(data.data.tot_conversion_count)} 번 전환`, mediaInfoId },
    ];

    const funnelData = [
        {
            className: `funnelImpressionData${mediaInfoId}`,
            items: [
                { name: '노출수', value: `${formatNumber(data.data.tot_impression)}회`, percent: `${data.data.tot_impression_variance.toFixed(2)}`, operator: `${data.data.tot_impression_variance_operator}` },
                { name: '총 광고비용', value: `${formatNumber(data.data.tot_cost)}원`, percent: `${data.data.tot_cost_variance.toFixed(2)}`, operator: `${data.data.tot_cost_variance_operator}` },
                { name: '노출당 비용', value: `${formatNumber(data.data.impression_cost)}원`, percent: `${data.data.impression_cost_variance.toFixed(2)}`, operator: `${data.data.impression_cost_variance_operator}` },
            ],
        },
        {
            className: `funnelClickData${mediaInfoId}`,
            items: [
                { name: '클릭수', value: `${formatNumber(data.data.tot_click)}회`, percent: `${data.data.tot_click_variance.toFixed(2)}`, operator: `${data.data.tot_click_variance_operator}` },
                { name: '클릭률', value: `${data.data.click_rate}%`, percent: `${data.data.click_rate_variance.toFixed(2)}`, operator: `${data.data.click_rate_variance_operator}` },
                { name: '클릭당 비용', value: `${formatNumber(data.data.cost_click)}원`, percent: `${data.data.cost_click_variance.toFixed(2)}`, operator: `${data.data.cost_click_variance_operator}` },
            ],
        },
        {
            className: `funnelConversionData${mediaInfoId}`,
            items: [
                { name: '전환수', value: `${formatNumber(data.data.tot_conversion_count)}회`, percent: `${data.data.tot_conversion_count_variance.toFixed(2)}`, operator: `${data.data.tot_conversion_count_variance_operator}` },
                { name: '전환율', value: `${data.data.conversion_rate}%`, percent: `${data.data.conversion_rate_variance.toFixed(2)}`, operator: `${data.data.conversion_rate_variance_operator}` },
                { name: '전환당 비용', value: `${formatNumber(data.data.conversion_cost)}원`, percent: `${data.data.conversion_cost_variance.toFixed(2)}`, operator: `${data.data.conversion_cost_variance_operator}` },
            ],
        },
    ];

    const message = () => {
        return (
            <>
            광고 성과의 단계를 순서대로 보여줍니다. 1단계는 광고가 사용자에게 얼마나 노출되었는지,<br/>
            2단계는 그 광고를 몇 명이 클릭했는지, 3단계는 클릭 후 전환이 얼마나 이루어졌는지를 확인합니다.
            </>
        )
    }

    return (
        <div className={styles.keywordFunnel}>
            <div className={styles.keywordFunnelTitle}>
                <p>단계별 진행 상황</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.keywordFunnelContent}>
                <div className={styles.keywordFunnelChart}>
                    {funnelSteps.map((step, index) => (
                        <FunnelStep key={index} {...step} />
                    ))}
                </div>
                <div className={styles.keywordFunnelDataBox}>
                    {funnelData.map((section, index) => (
                        <div key={index} className={styles.keywordFunnelDataItem}>
                            {section.items.map((item, itemIndex) => (
                                <DataItem key={itemIndex} {...item} className={section.className} />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
