import React from "react";

import styles from "../scss/predimpclick.module.scss";
import { ReactComponent as Labels } from "../../../../assets/images/predict/pred-imp-click-labels.svg"

import { PredResultLine } from "../../../utils/chart/PredChart";
import { InfoIcon } from "../../../utils/info/InfoIcon";

export const PredImpClick = ({ startDate, endDate }) => {
    const testData = [
        { date: "2024-08-08", impressions: 200, clicks: 10 },
        { date: "2024-08-09", impressions: 180, clicks: 12 },
        { date: "2024-08-10", impressions: 160, clicks: 15 },
        { date: "2024-08-11", impressions: 170, clicks: 13 },
        { date: "2024-08-12", impressions: 190, clicks: 18 },
        { date: "2024-08-13", impressions: 210, clicks: 20 },
        { date: "2024-08-14", impressions: 220, clicks: 25 }
    ]

    const message = () => {
        return (
            <>
            광고비 조정에 따라 예측된 노출수와 클릭수를 보여줍니다.
            </>
        )
    }

    return (
        <div className={styles.predImpClick}>
            <div className={styles.predImpTxt}>
                <p>예상 노출수, 클릭수</p>
                <InfoIcon message={message()} className="infoTxtBox" />
            </div>
            <div className={styles.predImpLabels}>
                <Labels />
            </div>
            <div className={styles.predImpChart}>
                <PredResultLine data={testData} />
                <div className={styles.lineBorder}></div>
            </div>
        </div>
    )
}