import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "../scss/modal/datalinkmodal.module.scss";
import { ReactComponent as ModalClose } from "../../../../assets/images/share/modal-close.svg";
import { ReactComponent as GoogleAds } from "../../../../assets/images/datalink/datalink-google.svg";
import { ReactComponent as GA4 } from "../../../../assets/images/datalink/datalink-ga4.svg";
import { ReactComponent as Naver } from "../../../../assets/images/datalink/datalink-naver.svg";
import { ReactComponent as Kakao } from "../../../../assets/images/datalink/datalink-kakaologo.svg";
import { ReactComponent as Facebook } from "../../../../assets/images/datalink/datalink-facebook.svg";

import FaceBookLink from "../social/facebook/FaceBookLink";
import KakaoLink from "../social/kakao/KakaoLink,";
import GoogleLink from "../social/google/GoogleLink";
import MediaLinkedListBox from "../box/MediaLinkedListBox";
import { NaverCheckAccount } from "../../../../api/socialLogin/NaverApi";
import Loading from "../../../utils/loading/Loading";
import { LinkSuccessModal } from "./LinkSuccessModal";
import KakaoMomentLink from "../social/kakaomoment/KakaoMomentLink";

const dataLinks = [
    { Icon: GA4, text: "구글 애널리틱스 4" },
    { Icon: Kakao, text: "카카오모먼트" },
    { Icon: Kakao, text: "카카오키워드" },
    { Icon: Facebook, text: "페이스북" },
    { Icon: GoogleAds, text: "구글광고" },
    { Icon: Naver, text: "네이버 검색광고" },
    { Icon: Naver, text: "네이버 GFA" }
];

const DataLinkModal = ({ show, handleClose, selectedLink }) => {
    // const [selectedLink, setSelectedLink] = useState(null);
    const facebookLinkRef = useRef();
    const kakaoLinkRef = useRef();
    const googleLinkRef = useRef();
    const navigate = useNavigate();

    const momentLinkRef = useRef();

    const handleFacebookButtonClick = () => {
        facebookLinkRef.current.triggerFBLogin();
    };

    const handleGa4BtnClick = () => {
        navigate("/datalink/ga4");
    }

    // const handleLinkClick = (link) => {
    //     setSelectedLink(link);
    // };

    // const handleCloseModal = () => {
    //     setSelectedLink(null);
    //     handleClose();
    // };

    // if (!show) {
    //     return null;
    // }

    // 네이버 인풋 폼 데이터
    const [naverFromData, setNaverFormData] = useState({
        accessLicense: '',
        secretKey: '',
        customerId: '',
        dataName: ''
    })
    const [connectCheck, setConnectCheck] = useState(true)
    const [connectLoading, setConnectLoading] = useState(false)
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNaverFormData({
            ...naverFromData,
            [name]: value, 
        });
    };
    const naverSearchSubmit = async () => {
        try{
            // console.log('전송할 데이터:', naverFromData);
            setConnectLoading(true)
            setConnectCheck(true)
            const response = await NaverCheckAccount(naverFromData)
            // console.log(response);

            if (response.status === 200) {
                console.log("status : ", response.status);
                setConnectCheck(true);
                setConnectLoading(false);
                setSuccessModalOpen(true);
            } else if (response.status === 208) {
                alert("이미 생성된 계정입니다.");
                setConnectCheck(true);
                setConnectLoading(false);
                window.location.href = "/datalink"
                // 208 상태 코드에 대한 추가 작업
            } else {
                setConnectCheck(false);
            }
        } catch (error) {
            setConnectCheck(false)
            
        } finally{
            setConnectLoading(false)
        }
        
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
        window.location.href = "/datalink"
    };

    if (!show) {
        return null;
    }

    
    return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modalContent}>
                <div className={styles.modalCloseBtn}>
                    <div onClick={handleClose} style={{ cursor: "pointer" }}>
                        <ModalClose />
                    </div>
                </div>
                <div className={styles.modalText}>
                    {/* {!selectedLink ? ( */}
                        {/* <>
                            <p className={styles.text1}>데이터 선택하기</p>
                            {/* <p className={styles.text2}>연결할 데이터를 선택해주세요.</p> */}
                        {/* </> */}
                    {/* ) : ( */}
                        <>
                            {selectedLink === "네이버 검색광고" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>네이버 검색 광고 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                            {selectedLink === "네이버 GFA" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>네이버 GFA 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                            {selectedLink === "구글 애널리틱스 4" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>GA4 데이터 연결을 요청해주세요.</p>
                                </>
                            )}
                            {selectedLink === "구글광고" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>구글 광고 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                            {selectedLink === "페이스북" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>페이스북 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                            {selectedLink === "카카오모먼트" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>카카오모먼트 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                            {selectedLink === "카카오키워드" && (
                                <>
                                    <p className={styles.text1}>데이터 연결하기</p>
                                    <p className={styles.text2}>카카오키워드 데이터 연결을 진행해주세요.</p>
                                </>
                            )}
                        </>
                    {/* )} */}
                </div>
                <MediaLinkedListBox selectedLink={selectedLink}/>
                <>
                    {selectedLink === "네이버 검색광고" && (
                        <>
                            <div className={styles.naverSA}>
                                <div className={styles.naverInputBox}>
                                    <p>Customer 아이디 입력</p>
                                    <input
                                        name="customerId"
                                        placeholder="Customer 아이디를 입력해주세요."
                                        value={naverFromData.customerId}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className={styles.naverInputBox}>
                                    <p>액세스 라이센스 입력</p>
                                    <input
                                        name="accessLicense"
                                        placeholder="액세스 라이센스를 입력해주세요."
                                        value={naverFromData.accessLicense}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className={styles.naverInputBox}>
                                    <p>비밀키 입력</p>
                                    <input
                                        name="secretKey"
                                        placeholder="비밀키를 입력해주세요."
                                        value={naverFromData.secretKey}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                
                                <div className={styles.naverInputBox}>
                                    <p>데이터 이름 입력</p>
                                    <input
                                        name="dataName"
                                        placeholder="저장할 이름을 입력해주세요."
                                        value={naverFromData.dataName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className={styles.rowContaioner}>
                            {connectCheck === false && 
                                    <div className={styles.cantDataLinkContainer}>
                                        <div className={styles.cantDataLinkBox}>
                                            <p className={styles.cantDataLink}>연결할 수 없습니다. 입력한 내용을 다시 확인해주세요.</p>
                                        </div>
                                    </div>
                                }
                            </div>
                                
                                
                            <div className={styles.naverLoginBtn}>
                                
                                
                                {connectLoading ? <button>연결중...</button> : <button onClick={naverSearchSubmit}>연결</button>}
                                
                            </div>
                            
                            
                        </>
                    )}
                    {selectedLink === "네이버 GFA" && (
                        <>
                            <p className={styles.text1}>{`${selectedLink} 선택됨`}</p>
                            <p className={styles.text2}>네이버 GFA 관련 추가 정보를 표시합니다.</p>
                        </>
                    )}
                    {selectedLink === "구글 애널리틱스 4" && (
                        <>
                            <div className={styles.ga4}>
                                <div>
                                    <p className={styles.text1} style={{marginBottom:20}}>요청 내역 확인하기</p>
                                    <p className={styles.text2}>연결 요청 내역이 없습니다.</p>
                                </div>
                                <div className={styles.ga4LoginBtn}>
                                    <button onClick={handleGa4BtnClick}>
                                        <div>GA4 연결 요청하기</div>
                                        <p>▶</p>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    {selectedLink === "구글광고" && (
                        <>
                            <GoogleLink ref={googleLinkRef} />
                            <div className={styles.socialLoginBtn}>
                                <button onClick={() => googleLinkRef.current.triggerGoogleLogin()}>구글 계정 정보 연결하기</button>
                            </div>
                        </>
                    )}
                    {selectedLink === "페이스북" && (
                        <>
                            <FaceBookLink ref={facebookLinkRef} />
                            {/* <div className={styles.socialLoginBox}></div> */}
                            <div className={styles.socialLoginBtn}>
                                <button onClick={handleFacebookButtonClick}>페이스북 계정 정보 연결하기</button>
                            </div>
                        </>
                    )}
                    {selectedLink === "카카오모먼트" && (
                        <>
                            <KakaoMomentLink ref={momentLinkRef} />
                            <div className={styles.socialLoginBtn}>
                                <button onClick={() => momentLinkRef.current.triggerMomentLogin()}>카카오모먼트 계정 정보 연결하기</button>
                            </div>
                        </>
                    )}
                    {selectedLink === "카카오키워드" && (
                        <>
                            <KakaoLink ref={kakaoLinkRef} />
                            <div className={styles.socialLoginBtn}>
                                <button onClick={() => kakaoLinkRef.current.triggerKakaoLogin()}>카카오키워드 계정 정보 연결하기</button>
                            </div>
                        </>
                    )}
                </>
            </div>
            {successModalOpen && (
                <LinkSuccessModal onClose={handleSuccessModalClose} />
            )}
        </div>
    );
};

export default DataLinkModal;
