import React, { useEffect, useState } from "react";
import Navbar from "../../shared/header/Header";
import axios from "../../api/axios";
import "./approveList.scss";
import CustomPagination from "../utils/pagination/Pagination";
import DataTable from "./approveTable/table/Table";
import Modal from "./modal/Modal";

const ApproveData = async () => {
  try {
    const { data } = await axios.get("common/approve/");
    return data; // 가져온 데이터 반환
  } catch (error) {
    console.error("데이터를 불러오는 중 오류 발생:", error);
    return null;
  }
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // "yyyy-mm-dd" 형식으로 변환
};

const blurUserName = (username) => {
  if (!username) return ""; // username이 null 또는 undefined인 경우 빈 문자열 반환
  if (username.length <= 2) {
    return username[0] + "*";
  } else {
    const blurredUsername =
      username.slice(0, 2) +
      username.slice(2, -2).replace(/./g, "*") +
      username.slice(-2);

    return blurredUsername;
  }
};

const blurMarketer = (marketer) => {
  if (!marketer) return ""; // marketer가 null 또는 undefined인 경우 빈 문자열 반환
  if (marketer.length >= 3) {
    const blurredMarketer =
      marketer.slice(0, 1) +
      marketer.slice(1, -1).replace(/./g, "*") +
      marketer.slice(-1);
    return blurredMarketer;
  } else {
    return marketer[0] + "*";
  }
};

const ApproveList = () => {
  const [approveData, setApproveData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false); // 모달 상태
  const [rejectReason, setRejectReason] = useState(""); // 반려 사유

  useEffect(() => {
    const fetchData = async () => {
      const data = await ApproveData();
      setApproveData(data);
    };
    fetchData();
  }, []);

  const views = 10;
  const headers = [
    {
      text: "번호",
      value: "index",
    },
    {
      text: "신청 ID",
      value: "username",
    },
    {
      text: "담당자",
      value: "marketer",
    },
    {
      text: "요청일",
      value: "date_joined",
    },
    {
      text: "상태",
      value: "approve",
    },
  ];

  const transformTableData = (data) => {
    if (data) {
      return data.map((item, index) => ({
        index: index + 1,
        username: blurUserName(item.username),
        marketer: blurMarketer(item.marketer),
        date_joined: formatDate(item.date_joined),
        approve: item.approve
          ? "승인"
          : item.is_active
            ? "승인 대기 중"
            : "반려 [보기]",
          companion: item.companion
      })).reverse();
    }
    return []; // data가 null인 경우 빈 배열 반환
  };

  const items = approveData ? transformTableData(approveData) : [];
  // console.log(approveData);

  const handleRowClick = (data) => {
    // console.log("reason :: ");
    // console.log(data);
    if (data.approve === "반려 [보기]") {
      setRejectReason(data.companion);
      setShowModal(true);
    }
  };

  return (
    <div className="main-box">
      <Navbar />
      <div className="approveList-box">
        <h1>요청 결과 조회하기</h1>
        <div className="approveTable-box">
          <DataTable
            headers={headers}
            items={items.slice((currentPage - 1) * views, currentPage * views)}
            onRowClick={handleRowClick}
          />
          <CustomPagination
            total={items.length}
            page={currentPage}
            setPage={setCurrentPage}
            views={views}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        companion={rejectReason}
      />
    </div>
  );
};

export default ApproveList;
