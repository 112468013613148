import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ModalClose } from "../../../../../assets/images/share/modal-close.svg";

import Home from "../../../../../page/home/Home";
import styles from "../../scss/social/facebookRedirect.module.scss";
import { SelectBox } from "../../../../utils/dataLinkBox/SelectBox";
import { InputBox } from "../../../../utils/dataLinkBox/InputBox";
import { LinkSuccessModal } from "../../modal/LinkSuccessModal";
import { KakaoRedirectApi } from "../../../../../api/socialRedirect/KakaoRedirectApi";

export const KakaoRedirect = () => {
    const [data, setData] = useState(null);
    const [modalOpen, setModalOpen] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]); 
    const [showInputContainer, setShowInputContainer] = useState(false); 
    const [inputData, setInputData] = useState({});
    const token = useSelector((state) => state.social.Code);
    const username = useSelector((state) => state.user.username);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const navigate = useNavigate();
    

    useEffect(() => {
        const storedData = localStorage.getItem("kakao-keyword");
        if (storedData) {
            setData(JSON.parse(storedData));
        }
    }, []);

    const closeModal = () => {
        setModalOpen(false);
    };

    const removeOption = (value) => {
        setSelectedOptions(prevOptions => prevOptions.filter(option => option.value !== value));
    };

    const handleInputChange = (newInputData) => {
        setInputData(newInputData);
    };

    const handleSubmit = async() => {
        if (selectedOptions.length === 0) {
            alert("선택된 데이터가 없습니다.");
            return ;
        }

        const payload = selectedOptions.map(option => ({
            value: option.value,
            label: option.label,
            input: inputData[option.value] || '' ,
            token: token,
            username: username,
            mediaLinkId: data.media_link_id
        }));

        // console.log("전송할 데이터:", payload);
        
        const response = await KakaoRedirectApi(payload);

        if (response) {
            // console.log("서버 응답:", response);
            closeModal();
            setSuccessModalOpen(true); 
        } else {
            console.error("서버 응답이 없습니다.");
        }
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
        navigate('/datalink');
    };

    return (
        <>
            <Home />
            {modalOpen && data && (
                <div className={styles.modalBackdrop}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalCloseBtn}>
                            <div onClick={closeModal} style={{ cursor: "pointer" }}>
                                <ModalClose />
                            </div>
                        </div>
                        <div className={styles.modalText}>
                            <p className={styles.text1}>데이터 선택하기</p>
                            <p className={styles.text2}>카카오키워드 데이터 연결을 진행해주세요.</p>
                        </div>
                        <SelectBox 
                            data={data}
                            onOptionsChange={setSelectedOptions} 
                            onShowInputChange={setShowInputContainer} 
                        />
                        {showInputContainer && (
                            <InputBox 
                                data={selectedOptions} 
                                removeOption={removeOption} 
                                onInputChange={handleInputChange}
                            />
                        )}
                        <div className={styles.linkBtnBox}>
                            <button onClick={handleSubmit}>연결</button>
                        </div>
                    </div>
                </div>
            )}
            {successModalOpen && (
                <LinkSuccessModal onClose={handleSuccessModalClose} />
            )}
        </>
    );
};
