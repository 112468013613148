import React, { useState, useEffect, useContext } from "react";

import { SummaryContext } from "../../../summary/DaSummaryProvider";

import { DaCostAvgTableApi } from "../../../../../api/reportDA/cost/DaCostAvhTableApi";

import { ReportDaTable } from "../../../../utils/table/ReportDaTable";
import Loading from "../../../../utils/loading/Loading";

export const DaAvgTable = ({
        mediaInfoId, 
        adAccountsId, 
        startDate,
        endDate
    }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setDay } = useContext(SummaryContext);

    const headers = ["지표/요일", "일", "월", "화", "수", "목", "금", "토"];

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 7) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    }

    const handleResponse = (response) => {
        const conversionData = response.data.find(item => item.row_name === "tot_conversion_amount");
        if (conversionData) {
            const days = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
            let maxDay = days[0];
            let maxValue = parseFloat(conversionData.sunday);

            days.forEach((day, index) => {
                const value = parseFloat(conversionData[["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][index]]);
                if (value > maxValue) {
                    maxValue = value;
                    maxDay = day;
                }
            });

            // console.log(`가장 큰 값의 요일: ${maxDay} (${maxValue})`);
            setDay({convAmount: maxDay});
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await DaCostAvgTableApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            handleResponse(response);
            // console.log(response)
        };
        fetchData();
    }, [startDate, endDate])

    if (loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    if (!data) {
        return null;
    }

    // const testData = {
    //     data: [
    //         {row_name: "평균매출", sunday: 22000, monday: 16, tuesday:22000, wednesday:2303, thursday: 1234, friday:3932, saturday:22002},
    //         {row_name: "매출 증감률", sunday: "-16", monday: "+21", tuesday:"+32", wednesday:"-43", thursday: "-12", friday:"+25", saturday:"-13"},
    //         {row_name: "평균 비용", sunday: 22000, monday: 22, tuesday:22000, wednesday:2303, thursday: 1234, friday:3932, saturday:2313},
    //         {row_name: "노출수", sunday: 2000, monday: 43, tuesday:22000, wednesday:2303, thursday: 1234, friday:3932, saturday:324},
    //         {row_name: "클릭수", sunday: 2000, monday: 12, tuesday:22000, wednesday:2303, thursday: 1234, friday:3932, saturday:1233},
    //         {row_name: "전환수", sunday: 2000, monday: 23, tuesday:22000, wednesday:2303, thursday: 1234, friday:3932, saturday:4322},
    //     ]
    // };

    const transformedData = data.data.map(item => {
        let rowName = "";
        if (item.row_name === "tot_conversion_amount") {
            rowName = "평균매출";
        } else if (item.row_name === "tot_conversion_amount_variance") {
            rowName = "매출 증감률";
        } else if (item.row_name === "tot_cost") {
            rowName = "평균 비용";
        } else if (item.row_name === "tot_impression") {
            rowName = "노출수";
        } else if (item.row_name === "tot_click") {
            rowName = "클릭수";
        } else if (item.row_name === "tot_conversion_count") {
            rowName = "전환수";
        } else {
            return null;
        }

        return {
            "지표/요일": rowName,
            "일": rowName === "매출 증감률" ? item.sunday : parseInt(item.sunday, 10), // int 형으로 변경
            "월": rowName === "매출 증감률" ? item.monday : parseInt(item.monday, 10), // int 형으로 변경
            "화": rowName === "매출 증감률" ? item.tuesday : parseInt(item.tuesday, 10), // int 형으로 변경
            "수": rowName === "매출 증감률" ? item.wednesday : parseInt(item.wednesday, 10), // int 형으로 변경
            "목": rowName === "매출 증감률" ? item.thursday : parseInt(item.thursday, 10), // int 형으로 변경
            "금": rowName === "매출 증감률" ? item.friday : parseInt(item.friday, 10), // int 형으로 변경
            "토": rowName === "매출 증감률" ? item.saturday : parseInt(item.saturday, 10), // int 형으로 변경
        };
    })
    .filter(item => item !== null) // null 값 필터링
    .sort((a, b) => {
        const order = ["평균매출", "매출 증감률", "평균 비용", "노출수", "클릭수", "전환수"];
        return order.indexOf(a["지표/요일"]) - order.indexOf(b["지표/요일"]);
    });
 
    return (
        <>
            <ReportDaTable 
                headers={headers} 
                items={transformedData} 
                mediaInfoId={mediaInfoId}
            />
        </>
    )
}