import axios from "../../axios";

export const DaDetailCampaignDataApi = async (media, startDate, endDate, accountId, page, row, orderBy ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/campaign`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/campaign`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/campaign`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                page_size: page,
                row_num: row,
                order_by: orderBy
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 성과 상세분석 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailCampaignTotalApi = async (media, startDate, endDate, accountId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/campaign-total`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/campaign-total`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/campaign-total`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 성과 상세분석 총합 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailCampaignDownloadApi = async (media, startDate, endDate, accountId, fields, orderBy, responseType) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/campaign-download`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/campaign-download`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/campaign-download`;
    }
    try {
        const response = await axios.get(path, {
            responseType: responseType,
            params: {
                start_dt: startDate,
                end_dt: endDate,
                fields: fields,
                order_by: orderBy
            }
        });
        return response;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 캠페인 성과 상세분석 엑셀 다운로드 오류 발생:", error);
        throw error;
    }
};

export const DaDetailGroupDataApi = async (media, startDate, endDate, accountId, page, row, orderBy, campaignId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/group`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/group`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/group`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                campaign_id: campaignId,
                start_dt: startDate,
                end_dt: endDate,
                page_size: page,
                row_num: row,
                order_by: orderBy,
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 성과 상세분석 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailGroupTotalApi = async (media, startDate, endDate, accountId, campaignId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/group-total`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/group-total`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/group-total`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                campaign_id: campaignId
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 성과 상세분석 총합 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailGroupDownloadApi = async (media, startDate, endDate, accountId, fields, orderBy, responseType, campaignId) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/group-download`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/group-download`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/group-download`;
    }
    try {
        const response = await axios.get(path, {
            responseType: responseType,
            params: {
                start_dt: startDate,
                end_dt: endDate,
                fields: fields,
                order_by: orderBy,
                campaign_id: campaignId
            }
        });
        return response;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 광고그룹 성과 상세분석 엑셀 다운로드 오류 발생:", error);
        throw error;
    }
};

export const DaDetailCreativeDataApi = async (media, startDate, endDate, accountId, page, row, orderBy, groupId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/creative`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/creative`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/creative`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                page_size: page,
                row_num: row,
                order_by: orderBy,
                ad_group_id: groupId
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 상세분석 소재 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailCreativeTotalApi = async (media, startDate, endDate, accountId, groupId ) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/creative-total`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/creative-total`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/creative-total`;
    }
    try {
        const { data } = await axios.get(path, {
            params: {
                start_dt: startDate,
                end_dt: endDate,
                ad_group_id: groupId
            }
        });
        return data;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 상세분석 소재 총합 데이터 오류 발생:", error);
        throw error;
    }
};

export const DaDetailCreativeDownloadApi = async (media, startDate, endDate, accountId, fields, orderBy, responseType, groupId) => {
    let path;
    if (media === 'kakao') {
        path = `${media}/moments/${accountId}/campaigns/result-detail-analysis/creative-download`;
    } else if (media === 'googleads') {
        path = `${media}/da/${accountId}/campaigns/result-detail-analysis/creative-download`;
    } else {
        path = `${media}/${accountId}/campaigns/result-detail-analysis/creative-download`;
    }
    try {
        const response = await axios.get(path, {
            responseType: responseType,
            params: {
                start_dt: startDate,
                end_dt: endDate,
                fields: fields,
                order_by: orderBy,
                group_id: groupId
            }
        });
        return response;
    } catch (error) {
        console.error("DA 캠페인/광고그룹 상세분석 소재 엑셀 다운로드 오류 발생:", error);
        throw error;
    }
};