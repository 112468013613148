import React from "react";

import styles from "./content.module.scss"

import { ReactComponent as Naver } from "../../../../assets/images/home/home-naver.svg"

export const NaverSA = ({handlePreviewClick}) => {
    return (
        <div className={styles.naverContent} onClick={handlePreviewClick}>
            <div className={styles.naverBox}>
                <div className={styles.naverImg}>
                    <Naver />
                </div>
                <p className={styles.naverTxt1}>네이버 SA 광고 성과 분석 보고서</p>
                <p className={styles.naverTxt2}>네이버 검색 광고 성과를 확인해보세요!</p>
                <p className={styles.naverTxt3}>미리보기 →</p>
            </div>
        </div>
    );
}