import React, { useState, useEffect } from "react";

import { DaPageTableApi, DaPageTableTotalApi } from "../../../../../api/reportDA/creative/DaPageTableApi";

import Loading from "../../../../utils/loading/Loading";
import { PredTable } from "../../../../utils/table/PredTable";


export const PrevWeekTable = ({data}) => {
    // const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(true);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         const response = await DaPageTableApi(mediaName(), startDate, endDate, adAccountsId);
    //         const totResponse = await DaPageTableTotalApi(mediaName(), startDate, endDate, adAccountsId);
    //         setData(response);
    //         setTotal(totResponse);
    //         setLoading(false);
    //         console.log(response);
    //     };
    //     fetchData();
    // }, [startDate, endDate]);

    // if (loading) {
    //     return (
    //         <>
    //             <Loading />
    //         </>
    //     );
    // }

    // if (!data) {
    //     return null;
    // }

    const headers = ["매체", "상품", "광고비", "노출수", "클릭수", "클릭률", "전환수", "전환 매출액", "광고수익률"]

    const transformedData = data.data.map(item => ({
        "매체": item.media,
        "상품": item.landing_type,
        "광고비": item.tot_cost,
        "노출수": item.tot_impression,
        "클릭수": item.tot_click,
        "클릭률": item.click_rate,
        "전환수": item.tot_conversion_count,
        "전환 매출액": item.tot_conversion_amount,
        "광고수익률": item.roas,
    }));


    return (
        <>
            <PredTable 
                headers={headers} 
                items={transformedData} 
                // mediaInfoId={mediaInfoId}
            />
        </>
    );
}