import React, { useState, useEffect, useContext } from 'react';

import styles from '../scss/deviceimpression.module.scss';

import { SummaryContext } from '../../../summary/SummaryProvider';

import { CostDeviceImpressionApi } from '../../../../../api/report/cost/CostDeviceImpressionApi';

import { DevicePieChart } from '../../../../utils/chart/DevicePieChart';
import Loading from '../../../../utils/loading/Loading';

export const DeviceImpression = ({mediaInfoId, adAccountsId, startDate, endDate}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState("cost");
    const { setDeviceCompCost } = useContext(SummaryContext);

    const mediaName = () => {
        if (mediaInfoId === 5) { 
            return "naver"; 
        } else if (mediaInfoId === 89) { 
            return "kakao"; 
        } else if (mediaInfoId === 100) { 
            return "googleads"; 
        }
        return "unknown";
    };

    const compareCost = (data) => {
        const moData = data.find(item => item.device_type === "MO");
        const pcData = data.find(item => item.device_type === "PC");

        if (moData && pcData) {
            const moCost = moData.conversion_cost;
            const pcCost = pcData.conversion_cost;

            const diffCost = ((moCost - pcCost) / pcCost) * 100; // 비율 계산

            if (diffCost > 0) {
                setDeviceCompCost( {cost: ["MO", diffCost.toFixed(2)]});
            } else if (diffCost < 0) {
                setDeviceCompCost( {cost: ["PC", Math.abs(diffCost.toFixed(2))]});
            }
        }
        return null; // 조건에 맞지 않으면 null 반환
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await CostDeviceImpressionApi(mediaName(), startDate, endDate, adAccountsId);
            setData(response);
            setLoading(false);
            compareCost(response.data);
        };
        fetchData();
    }, [startDate, endDate, selectedValue]);

    if (loading) {
        return (
            <div className={styles.deviceImpression}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return null;
    }

    const color1 = () => {
        if (mediaInfoId === 5) {
            return 'rgba(0, 176, 80, 1)';
        } else if (mediaInfoId === 89) {
            return 'rgba(255, 180, 18, 1)';
        } else if (mediaInfoId === 100) {
            return 'rgba(0, 114, 240, 1)';
        }
        return ;
    };

    const color2 = () => {
        if (mediaInfoId === 5) {
            return "rgba(0, 176, 80, 0.2)";
        } else if (mediaInfoId === 89) {
            return "rgba(255, 180, 18, 0.2)";
        } else if (mediaInfoId === 100) {
            return "rgba(0, 114, 240, 0.2)";
        }
        return ;
    };

    const color3 = () => {
        if (mediaInfoId === 100) {
            return "#76B7FF"
        }
        return ;
    }

    const chartData = data.data.map(item => ({
        device: item.device_type, // day_week 값을 사용
        value: selectedValue === "impression" ? item.impression_cost :
               selectedValue === "click" ? item.cost_click :
               selectedValue === "conversion" ? item.conversion_cost :
               item.tot_cost
    }));

    return (
        <div className={styles.deviceImpression}>
            <div className={styles.deviceImpressionTitle}>
                <p>PC/MO 별 광고비</p>
                <div className={styles[`deviceImpressionSelect${mediaInfoId}`]}>
                    <select onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <option value="cost">총 광고비</option>
                        <option value="impression">노출당 비용</option>
                        <option value="click">클릭당 비용</option>
                        <option value="conversion">전환당 비용</option>
                    </select>
                </div>
            </div>
            <div className={styles.deviceImpressionContent}>
                <DevicePieChart data={chartData} color1={color1()} color2={color2()} color3={color3()} selectedValue={selectedValue} />
            </div>
        </div>
    )
}