import React from "react";

import styles from "../scss/predscorecard.module.scss";

import { InfoIcon } from "../../../utils/info/InfoIcon";


export const PredScoreCard = ({ startDate, endDate, slashStartDate, slashEndDate }) => {
    
    
    const testData = {
        tot_cost: 3211,
        tot_cost_variance: 32,
        tot_cost_variance_operator: "up",
        impression_cost: 4324,
        impression_cost_variance: 32.23,
        impression_cost_variance_operator: "down",
        tot_click: 32422,
        tot_click_variance: 23.43,
        tot_click_variance_operator: "up",
        tot_conversion_count: 6433,
        tot_conversion_count_variance: 15.38,
        tot_conversion_count_variance_operator: "down",
        tot_conversion_amount: 3222,
        tot_conversion_amount_variance: 34.12,
        tot_conversion_amount_variance_operator: "up"
    }

    const transformedData = [
        {name: '광고비', value: `${testData.tot_cost.toLocaleString()}`, change: testData.tot_cost_variance, operator: testData.tot_cost_variance_operator},
        {name: '노출수', value: `${testData.impression_cost.toLocaleString()}`, change: testData.impression_cost_variance, operator: testData.impression_cost_variance_operator},
        {name: '클릭수', value: `${testData.tot_click.toLocaleString()}`, change: testData.tot_click_variance, operator: testData.tot_click_variance_operator},
        {name: '전환수', value: `${testData.tot_conversion_count.toLocaleString()}`, change: testData.tot_conversion_count_variance, operator: testData.tot_conversion_count_variance_operator},
        {name: '전환 매출액', value: `${testData.tot_conversion_amount.toLocaleString()}`, change: testData.tot_conversion_amount_variance, operator: testData.tot_conversion_amount_variance_operator},
        {name: '전환 매출액', value: `${testData.tot_click.toLocaleString()}`, change: testData.tot_click_variance, operator: testData.tot_click_variance_operator},
        {name: '전환 매출액', value: `${testData.tot_click.toLocaleString()}`, change: testData.tot_click_variance, operator: testData.tot_click_variance_operator}
    ]

    const getChangeClassName = (operator) => {
        if (operator === "up") return styles.positive;
        if (operator === "down") return styles.negative;
        return '';
    }

    const message = () => {
        return (
            <>
            조정된 광고비를 기준으로 예측된 다음주 전체 광고 성과입니다.
            </>
        )
    }

    return (
        <>
            <div className={styles.predTitle}>
                <p>{slashStartDate} ~ {slashEndDate} 예측 결과 총합</p>
                <InfoIcon message={message()} className="infoTxtBox2" margin="370px" />
            </div>
            <div className={styles.predScoreCard}>
            {transformedData.map((item, index) => (
                <div key={index} className={styles.scoreCard}>
                    <div className={styles.scoreCardTitle}>{item.name}</div>
                    <div className={styles.scoreCardValue}>{item.value}</div>
                    <div className={`${styles.scoreCardChange} ${getChangeClassName(item.operator)}`}>
                        {item.operator === "up" ? `+${item.change}%` : item.operator === "down" ? `-${item.change}%` : "-"}
                    </div>
                </div>
            ))}
            </div>
        </>
    );
}