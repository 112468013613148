import React from 'react';
import { useSelector } from 'react-redux';

import styles from './reportclick.module.scss';

import { ReportScoreCard } from './scorecard/ReportScoreCard';
import { ClickTrend } from './trend/ClickTrend';
import { DayImpression } from './impression/DayImpression';
import { DeviceImpression } from './impression/DeviceImpression';
import { DayPerformanceClick } from './performance/DayPerformanceClick';
import { CampaignClick } from './campaign/CampaignClick';
import { DetailedAnalysis } from './campaign/DetailedAnalysis';

import { selectStartDate, selectEndDate } from '../../../../store/dateSlice';

const formatDate = (dateString) => {
    const date = new Date(dateString); // 문자열을 Date 객체로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`; // YYYYMMDD 형식으로 반환
};

export const ReportClick = ({mediaInfoId, adAccountsId}) => {
    const startDate = formatDate(useSelector(selectStartDate));
    const endDate = formatDate(useSelector(selectEndDate));

    return (
        <div className={styles.reportClick}>
            <ReportScoreCard mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <ClickTrend mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <div className={styles.clickImpressions}>
                <DayImpression mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
                <DeviceImpression mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            </div>
            <DayPerformanceClick mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <CampaignClick mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
            <DetailedAnalysis mediaInfoId={mediaInfoId} startDate={startDate} endDate={endDate} adAccountsId={adAccountsId} />
        </div>
    );
};

