import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./createdlist.module.scss";
import { ReactComponent as Option } from "../../../../assets/images/home/home-reportlist.svg";

import { MyPredictApi } from "../../../../api/report/MyReportApi";
import { DeletePredictApi } from "../../../../api/report/DeleteReportApi";
import { ChangePredictNameApi } from "../../../../api/report/ChangeReportNameApi";

import { DeleteModal } from "../../../sidebar/home/modal/DeleteModal";
import { ModifyModal } from "../../../sidebar/home/modal/ModifyModal";


export const CreatedList = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reportName, setReportName] = useState("");
    const [incrementId, setIncrementId] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [modifyModalIndex, setModifyModalIndex] = useState(null);
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await MyPredictApi();
        setData(response);
        setLoading(false);
        // console.log(response);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // 모달 외부 클릭 시 모달 닫기 처리
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(null);
            }
        };
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOptionClick = (index) => {
        setIsModalOpen(isModalOpen === index ? null : index);
    };

    const handleDeleteModal = (reportName, incrementId) => {
        setReportName(reportName);
        setIncrementId(incrementId);
        setDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const handleDeleteConfirm = async (incrementId) => {
        try {
            await DeletePredictApi(incrementId);
            setDeleteModal(false);
            fetchData();
        } catch (error) {
            console.error("보고서 삭제 중 오류 발생:", error);
        }
    };

    const handleModifyModal = (reportName, incrementId, index) => {
        setReportName(reportName);
        setIncrementId(incrementId);
        setModifyModalIndex(index);
    };

    const closeModifyModal = () => {
        setModifyModalIndex(null);
    };

    const handleModifyConfirm = async (incrementId, newReportName) => {
        try {
            await ChangePredictNameApi(incrementId, newReportName);
            setModifyModalIndex(null);
            fetchData();
        } catch (error) {
            console.error("보고서 이름 변경 중 오류 발생:", error);
            alert("같은 이름의 보고서가 이미 존재합니다.");
        }
    };

    const reportType = (type) => {
        if (type === "preiod_report") {
            return "주간 월간 예측모델";
        } else if (type === "cost_report") {
            return "광고비 입력 예측모델";
        }
        return "";
    };

    const linkDataNm = (data) => {
        return (
            <>
                {data.map((item) => (
                    <li key={item.ad_account.link_data_nm}>{item.ad_account.link_data_nm}</li>
                ))}
            </>
        )
    }

    const handleReportMove = ({reportName, incrementId, type, linkedData}) => {
        let path = "/predict"
        if (type === "preiod_report") {
            path = "/predict/period"
        }
        if (type === "cost_report") {
            path = "/predict/advcost"
        }
        navigate(path, { state: {reportName: reportName, incrementId: incrementId, linkedData: linkedData} })
    }

    return (
        <div className={styles.creratedList}>
            {!loading && data?.data ? (
                <div className={styles.listContainer}>
                    {data.data.map((report, index) => (
                    <div key={index} className={styles.listBox}>
                        <div onClick={() => handleOptionClick(index)} className={styles.optionIcon}>
                            <Option />
                            {isModalOpen === index && (
                                <div ref={modalRef} className={styles.modal}>
                                    <button onClick={() => handleModifyModal(report.name, report.prediction_reports[0].prediction_report_id, index)}>이름 수정하기</button>
                                    <button onClick={() => handleDeleteModal(report.name, report.prediction_reports[0].prediction_report_id)}>삭제</button>
                                </div>
                                
                            )}
                        </div>
                        {deleteModal && (
                            <DeleteModal reportName={reportName} incrementId={incrementId} onClose={closeDeleteModal} onConfirm={handleDeleteConfirm} />
                        )}
                        {modifyModalIndex === index && (
                            <ModifyModal 
                                reportName={report.name} 
                                incrementId={report.prediction_reports[0].prediction_report_id} 
                                onClose={closeModifyModal} 
                                onConfirm={handleModifyConfirm} 
                            />
                        )}
                        <p className={styles.listName}>{report.name}</p>
                        <div className={styles.predName}>
                            <p>{reportType(report.type)}</p>
                        </div>
                        <div className={styles.dataBox}>
                            <p>연결한 데이터</p>
                            <ul>
                                {linkDataNm(report.prediction_reports)}
                            </ul>
                        </div>
                        <div className={styles.btnBox}>
                            <p onClick={() => handleReportMove({ reportName: report.name, incrementId: report.increment_id, type: report.type, linkedData: report.prediction_reports })}>보러가기 &nbsp;&gt;</p>
                        </div>
                    </div>
                    ))}
                </div>
            ) : (
                <div className={styles.noData}>
                    <p className={styles.noDataTxt}>예 측 모 델 을&nbsp;&nbsp;&nbsp;생 성 해 주 세 요 .</p>
                </div>
            )}
        </div>
    )
}