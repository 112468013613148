import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styles from './reportda.module.scss';
import { ReactComponent as Memo } from '../../../assets/images/report/report-sa-memo.svg';
import { ReactComponent as Calendar } from '../../../assets/images/report/report-sa-calendar.svg';

import { localDate } from '../../../store/dateSlice';

import { TargetResult } from '../target/TargetResult';
import { DaSummary } from '../summary/DaSummary';
import { ReportCalendar } from '../calendar/ReportCalendar';
import { DaSummaryProvider } from '../summary/DaSummaryProvider';
import { ReportCostDa } from './cost/ReportCostDa';
import { ReportCampaignDa } from './campaign/ReportCampaignDa';
import { ReportCreativeDa } from './creative/ReportCreativeDa';

export const ReportDA = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectedButton, setSelectedButton] = useState('cost');
    const [popup, setPopup] = useState();
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const reportName = location.state?.reportName
    const adAccountsId = location.state?.adAccountsId
    const mediaInfoId = location.state?.mediaInfoId
    const reportType = location.state?.reportType
    // const mediaInfoId = 6
    const reportId = location.state?.reportId
    const [resultData, setResultData] = useState({conversion: null, conversionAmount: null })

    const initialStartDate = new Date();
    const initialEndDate = new Date();
    initialStartDate.setDate(initialStartDate.getDate() - 8);
    initialEndDate.setDate(initialEndDate.getDate() - 2);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const startDate = formatDate(initialStartDate);
        const endDate = formatDate(initialEndDate);

        // Redux 상태 업데이트
        dispatch(localDate({ startDate, endDate }));

        // localStorage 초기화
        localStorage.setItem('startDate', startDate);
        localStorage.setItem('endDate', endDate);
    }, [reportId, dispatch]);
    
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const handleMemoClick = () => {
        openEditorWindow();
    };

    const handleCalendarClick = () => {
        setIsCalendarOpen(true);
    };

    const handleCloseCalendar = () => {
        setIsCalendarOpen(false);
    };

    const openEditorWindow = () => {
        const currentOrigin = window.location.origin;
        const editorWindow = window.open(
            `${currentOrigin}/report/memo`,
            "_blank",
            "width=700,height=800"
        );
        setPopup(editorWindow);
    };

    const getButtonClassName = (buttonType) => {
        if (mediaInfoId === 6) {
            return selectedButton === buttonType ? styles.reportNaverDaButtonActive : styles.reportNaverDaButton;
        } else if (mediaInfoId === 7) {
            return selectedButton === buttonType ? styles.reportKakaoDaButtonActive : styles.reportKakaoDaButton;
        } else if (mediaInfoId === 100) {
            return selectedButton === buttonType ? styles.reportGoogleDaButtonActive : styles.reportGoogleDaButton;
        }
        // 추가 조건을 여기에 계속 추가할 수 있습니다.
        return styles.reportDaButton; // 기본 클래스
    };

    return (
        <DaSummaryProvider>
            <div className={styles.reportDa}>
                <div className={styles.reportDaHeader}>
                    <p className={styles.reportDaHeaderTitle}>{reportName}</p>
                    {isCalendarOpen && <ReportCalendar handleCloseModal={handleCloseCalendar} />}
                    <div className={styles.reportDaHeaderIcon}>
                        
                        <Calendar onClick={handleCalendarClick} style={{cursor: 'pointer'}} />
                        <div onClick={handleMemoClick} style={{cursor: 'pointer'}}>
                            <Memo />
                        </div>
                    </div>
                </div>
                <div className={styles.reportDaButtonContainer}>
                    <div className={styles.reportDaButtonBox}>
                        <button 
                            className={getButtonClassName('cost')} 
                            onClick={() => handleButtonClick('cost')}
                        >
                            전환/비용
                        </button>
                        <button 
                            className={getButtonClassName('campaign')} 
                            onClick={() => handleButtonClick('campaign')}
                        >
                            캠페인/광고그룹
                        </button>
                        <button 
                            className={getButtonClassName('subject')} 
                            onClick={() => handleButtonClick('subject')}
                        >
                            소재
                        </button>
                    </div>
                </div>
                <div className={styles.reportDaContent}>
                    {selectedButton === 'cost' && <ReportCostDa mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} setResultData={setResultData} reportType={reportType} />}
                    {selectedButton === 'campaign' && <ReportCampaignDa mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} reportType={reportType} />}
                    {selectedButton === 'subject' && <ReportCreativeDa mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} reportType={reportType} />}
                    <div>
                        <TargetResult mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} reportId={reportId} resultData={resultData} reportType={reportType} />
                        <DaSummary mediaInfoId={mediaInfoId} adAccountsId={adAccountsId} selectedButton={selectedButton} />
                    </div>
                </div>
            </div>
        </DaSummaryProvider>
    );
};
