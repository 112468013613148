import React from "react";

import styles from "../scss/resultweekly.module.scss"
import { ReactComponent as Labels } from "../../../../../assets/images/predict/pred-singlebarline-labels.svg"

import { PredSingleBarLine } from "../../../../utils/chart/PredChart";

export const ResultBarLine = () => {
    const testData = {
        "data": [
            {
                "date": "2024-10-14",
                "roas": 0.43,
                "tot_conversion_amount": 94305
            },
            {
                "date": "2024-10-15",
                "roas": 0.57,
                "tot_conversion_amount": 116297
            },
            {
                "date": "2024-10-16",
                "roas": 0.21,
                "tot_conversion_amount": 64160
            },
            {
                "date": "2024-10-17",
                "roas": 0.85,
                "tot_conversion_amount": 48074
            },
            {
                "date": "2024-10-18",
                "roas": 0.62,
                "tot_conversion_amount": 8946
            },
        ]
    }

    return (
        <div className={styles.resultBarLine}>
            <div className={styles.barLineBorder}></div>
            <Labels />
            <PredSingleBarLine data={testData} width="550px" height="270px" />
        </div>
    )
}